
import { Component, Vue } from "vue-property-decorator";
import AddSubmissionGroupLabelModal from "@/components/modals/AddSubmissionGroupLabelModal.vue";
import { getModule } from "vuex-module-decorators";
import SubmissionModule from "@/modules/submission";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";
import SubmissionGroupLabelItem from "@/components/submission-group-labels/SubmissionGroupLabelItem.vue";

@Component({
  components: { SubmissionGroupLabelItem, AddSubmissionGroupLabelModal }
})
export default class SubmissionGroupLabels extends Vue {
  submissionState = getModule(SubmissionModule, this.$store);

  get alreadySelectedLabelIds() {
    return this.submissionState.SelectedSubmissionGroupLabels.map(
      item => item.LabelId
    );
  }

  addItem(submissionGroupLabel: SubmissionGroupLabel) {
    this.submissionState.addSubmissionGroupLabel(submissionGroupLabel);
  }
}
