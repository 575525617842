import BaseEntity from "../BaseEntity";
import Guid from "../Guid";
import TSEnumeration from "../Enumeration";

export default class SeedOrderClassGrade extends BaseEntity {
  SeedOrderClassId = Guid.Empty;
  NumStudents = 1;
  GradeId: string = Guid.Empty;
  Grade: TSEnumeration = new TSEnumeration();
}
