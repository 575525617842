
import { Component, Vue } from "vue-property-decorator";
import SchoolInfo from "../components/SchoolInfo.vue";
import ClassroomInfo from "../components/classroom-info/ClassroomInfo.vue";
import MailingInfo from "../components/MailingInfo.vue";
import OrderConfirmation from "../components/OrderConfirmation.vue";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import SeedOrderApiService from "@/services/SeedOrderApiService";
import SeedYearApiService from "@/services/SeedYear/SeedYearApiService";
import SeedYear from "@/types/SeedYear/SeedYear";
import ThankYou from "@/views/ThankYou.vue";
import ClassroomForm from "@/components/forms/ClassroomForm.vue";
import SelectSeasonForm from "@/components/forms/SelectSeasonForm.vue";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("OrderSeeds") as string
    };
  },
  components: {
    SelectSeasonForm,
    ClassroomForm,
    SchoolInfo,
    ClassroomInfo,
    MailingInfo,
    OrderConfirmation,
    ThankYou
  }
})
export default class OrderSeeds extends Vue {
  currentStep = 1;
  termsAccepted = false;
  order: SeedOrder = new SeedOrder();
  apiService: SeedOrderApiService = new SeedOrderApiService();
  seedYearApiService: SeedYearApiService = new SeedYearApiService();
  seedYears: SeedYear[] = [];
  errMsg = "";
  showThankYou = false;
  seedOrders: SeedOrder[] = [];
  seedOrderApiService: SeedOrderApiService = new SeedOrderApiService();
  submitting = false;

  get CurrentAndFutureSeedYears(): SeedYear[] {
    return this.seedYears.filter(x => new Date(x.EndDate) > new Date());
  }

  mounted() {
    this.setupTableData();
  }

  setupTableData() {
    this.seedOrderApiService
      .All([
        this.seedOrderApiService.GetSeedOrdersForSubject(),
        this.seedYearApiService.GetSeedYears()
      ])
      .then(response => {
        this.seedOrders = response[0].Value;
        this.seedYears = response[1].Value;
      });
  }

  NextStep() {
    this.errMsg = "";
    let valid = true;
    switch (this.currentStep) {
      case 1:
        valid = (this.$refs.SelectSeasonForm as SelectSeasonForm).valid;
        break;
      case 2:
        valid = (this.$refs.SchoolInfo as SchoolInfo).valid;
        break;
      case 3:
        valid = (this.$refs.ClassroomInfo as ClassroomForm).valid;
        break;
      case 4:
        valid = (this.$refs.MailingInfo as MailingInfo).valid;
        break;
    }

    if (valid) this.currentStep += 1;
  }

  Cancel() {
    this.reset();
  }

  PreviousStep() {
    this.errMsg = "";
    this.currentStep -= 1;
  }

  BackToStart() {
    this.errMsg = "";
    this.currentStep = 1;
  }

  reset() {
    this.showThankYou = false;
    this.order = new SeedOrder();
    this.BackToStart();
    this.setupTableData();
  }

  onSubmitClick() {
    if (!this.submitting) {
      this.submitting = true;
      this.order.Locale = this.$i18n.locale;
      this.apiService
        .CreateSeedOrder(this.order)
        .then(resp => {
          if (resp.Success) {
            this.showThankYou = true;
          } else {
            this.$router.push("/Error");
          }
        })
        .catch(ex => {
          this.errMsg = ex.response.data.Message;
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
}
