//interface for environment configurations
interface EnvironmentConfig {
  idUrl: string;
  apiUrl: string;
  showVersion: boolean;
}

// Development configuration
const DevelopmentConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "",
  apiUrl: process.env.VUE_APP_LTSOL_APIS_URL || "",
  showVersion: process.env.VUE_APP_SHOW_VERSION === "true"
};

// Sandbox configuration
const SandboxConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "https://auth.sandbox.test.letstalkscience.ca",
  apiUrl: process.env.VUE_APP_LTSOL_APIS_URL || "https://api.sandbox.test.letstalkscience.ca",
  showVersion: false
};

// Staging configuration
const StagingConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "https://auth.staging.ltstest.ca",
  apiUrl: process.env.VUE_APP_LTSOL_APIS_URL || "https://api.staging.ltstest.ca/",
  showVersion: false
};

// Production configuration
const ProductionConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "https://auth.letstalkscience.ca",
  apiUrl: process.env.VUE_APP_LTSOL_APIS_URL || "https://api.letstalkscience.ca",
  showVersion: false
};

// Utility function to get the right configuration for the current environment
function GetConfigForEnvironment(): EnvironmentConfig {
  const currentHost = window.location.host;

  if (process.env && process.env.NODE_ENV === "development") {
    return DevelopmentConfig;
  } else if (currentHost.includes("sandbox")) {
    return SandboxConfig;
  } else if (currentHost.includes("staging")) {
    return StagingConfig;
  } else {
    return ProductionConfig;
  }

}

export { GetConfigForEnvironment };
