
import { Component, Prop, Vue } from "vue-property-decorator";
import SeedYear from "../../../types/SeedYear/SeedYear";
import SeedYearLocale from "@/types/SeedYear/SeedYearLocale";

@Component({})
export default class SeedYearListItem extends Vue {
  @Prop({ default: () => new SeedYear() }) value!: SeedYear;

  get Description() {
    return this.seedYearLocale().Description;
  }

  get PlantingPeriod() {
    return this.seedYearLocale().PlantingPeriod;
  }

  get ShippingWindow() {
    return this.seedYearLocale().ShippingWindow;
  }

  get StartDate() {
    return this.value.StartDate.substr(0, 10);
  }

  get EndDate() {
    return this.value.EndDate.substr(0, 10);
  }

  seedYearLocale(): SeedYearLocale {
    const locale: string = this.$i18n.locale;
    return (
      this.value.SeedYearLocales.find(x => x.Locale === locale) ||
      new SeedYearLocale(locale)
    );
  }

  editItem() {
    this.$router.push(`/Admin/SeedYear/Update?id=${this.value.Id}`);
  }
}
