import SeedYear from "@/types/SeedYear/SeedYear";

export const mapToSeedYearInstance = (
  years: Array<SeedYear>
): Array<SeedYear> => {
  return years.map<SeedYear>(year => {
    return new SeedYear(
      year.IsActive,
      year.StartDate,
      year.EndDate,
      year.SeedYearSubmissionGroups,
      year.SeedYearLocales,
      year.Id,
      year.Deleted
    );
  });
};
