import BaseEntity from "../BaseEntity";
import SeedLabelSubmissionItem from "./SeedLabelSubmissionItem";
import AuxOrganization from "../AuxOrganization";

export default class SeedLabelSubmissionHeader extends BaseEntity {
  SeedLabelSubmissionItems: SeedLabelSubmissionItem[] = [];
  OrganizationId = "";
  AuxOrganizationId = "";
  AuxOrganization: AuxOrganization | undefined;
  Locale = "";
}
