import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"value":_vm.show,"width":"600"},on:{"click:outside":_vm.toggle}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(`${_vm.$t("Update")} ${_vm.$t("Grade")}`)+" ")]),_c(VForm,{ref:"editGradeModalForm",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VCardText,[_c(VSelect,{attrs:{"label":_vm.$t('Grades'),"items":_vm.AvailableGrades,"item-text":_vm.itemText,"item-value":_vm.itemValue,"rules":_vm.gradeSelectRules(),"required":""},model:{value:(_vm.CurrentGrade),callback:function ($$v) {_vm.CurrentGrade=$$v},expression:"CurrentGrade"}}),_c('number-input',{attrs:{"label":_vm.$t('Rule_NumStudents'),"rules":_vm.numberOfStudentInputRules(),"min":_vm.minNumberOfStudents,"max":_vm.maxNumberOfStudents,"hint":_vm.numberInputHint,"required":""},model:{value:(_vm.value.NumStudents),callback:function ($$v) {_vm.$set(_vm.value, "NumStudents", $$v)},expression:"value.NumStudents"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c(VSpacer),_c(VBtn,{staticClass:"primaryred",attrs:{"dark":""},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t("Update"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }