
import { Component, Prop, Vue } from "vue-property-decorator";
import Guid from "../../types/Guid";
import LabelLookup from "@/components/LabelLookup.vue";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import YearLabel from "@/types/YearLabel";

@Component({
  components: { LabelLookup }
})
export default class AddYearLabel extends Vue {
  @Prop({ default: () => Guid.Empty }) seedYearId!: string;
  @Prop({ default: () => [] }) alreadySelectedLabelIds!: Array<string>;
  show = false;
  isFormValid = false;
  yearLabel: YearLabel = new YearLabel();
  label: SeedLabel = new SeedLabel();
  errorMessages: Array<string> = [];

  toggle() {
    this.show = !this.show;
  }

  get lookupRules() {
    return [() => this.isLabelSelected && this.isLabelAlreadySelected];
  }

  get isLabelSelected() {
    return this.yearLabel.SeedLabelId !== Guid.Empty;
  }

  get isLabelAlreadySelected() {
    return (
      this.alreadySelectedLabelIds.find(
        item => item === this.yearLabel!.SeedLabelId
      ) === undefined
    );
  }

  Validate() {
    (this.$refs.AddYearLabelForm as HTMLFormElement).validate();
    this.errorMessages = [];
    if (!this.isLabelSelected)
      this.errorMessages.push(this.$t("SelectLabel") as string);

    if (!this.isLabelAlreadySelected)
      this.errorMessages.push(this.$t("LabelAlreadySelected") as string);
  }

  addLabel(label: SeedLabel) {
    if (!label) {
      this.reset();
      this.Validate();
      return;
    }
    this.label = label;
    this.yearLabel.SeedYearId = this.seedYearId;
    this.yearLabel.SeedLabelId = this.label.Id;
    this.yearLabel.SeedLabel = this.label;
    this.Validate();
  }

  save() {
    this.Validate();
    if (!this.isFormValid) return;

    this.toggle();
    this.$emit("save", this.yearLabel);
    this.reset();
  }

  reset() {
    this.yearLabel = new YearLabel();
    this.label = new SeedLabel();
  }
}
