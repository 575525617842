
import Vue from "vue";
import axios from "axios";
import { Component, Prop, Watch } from "vue-property-decorator";
import DataObject from "@/types/DataObject";

class FindItem {
  Id = "";
  Text = "";
  Description = "";
  Next = "";
  LastId = "";

  get ItemText(): string {
    return `${this.Text} - ${this.Description}`;
  }

  constructor(i: string, t: string, d: string, n: string, lid: string) {
    this.Id = i;
    this.Text = t;
    this.Description = d;
    this.Next = n;
    this.LastId = lid;
  }
}

@Component
export default class CanadaPostAddressLookup extends Vue {
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(Array) rules: Function[] | undefined;
  selectedItem: FindItem = new FindItem("", "", "", "", "");
  items: FindItem[] = [];

  loading = false;
  search = "";
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  // eslint-disable-next-line
  timeout: any | null;
  apiKey = "TM73-RB34-RJ36-CD59";
  findApi =
    "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws";
  retrieveApi =
    "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3.ws";

  @Watch("search")
  onSearchChanged() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.doSearch, this.typeTimer);
  }

  doSearch(p: DataObject | undefined) {
    // Items have already been requested
    if (this.loading) return;
    this.loading = true;

    let params: DataObject = new DataObject();
    if (p) {
      params = p;
    } else {
      params["Key"] = this.apiKey;
      params["SearchTerm"] = this.search;
      params["SearchFor"] = "Everything";
      params["Country"] = "CAN";
      params["LanguagePreference"] = this.$i18n.locale;
      params["MaxSuggestions"] = 10;
      params["MaxResults"] = 100;
    }

    axios
      .get(this.findApi, {
        params: params
      })
      .then(response => {
        const findItems: FindItem[] = [];
        response.data.Items.forEach((i: DataObject) => {
          if (!i.Error) {
            const lastId: string = i.Next === "Find" ? i.Id : "";
            findItems.push(
              new FindItem(i.Id, i.Text, i.Description, i.Next, lastId)
            );
          }
        });

        this.items = findItems;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  getUnitNumbers(item: FindItem) {
    const params: DataObject = new DataObject();
    params["Key"] = this.apiKey;
    params["SearchTerm"] = item.Text;
    params["SearchFor"] = "Everything";
    params["Country"] = "CAN";
    params["LanguagePreference"] = this.$i18n.locale;
    params["MaxSuggestions"] = 10;
    params["MaxResults"] = 500;
    params["LastId"] = item.LastId;

    this.doSearch(params);
  }

  onInput() {
    if (this.selectedItem) {
      const params = {
        Key: this.apiKey,
        Id: this.selectedItem.Id
      };

      axios
        .get(this.retrieveApi, {
          params: params
        })
        .then(response => {
          const found: DataObject | undefined = response.data.Items.find(
            (x: DataObject) => x.Language === "ENG"
          );
          if (found) {
            this.$emit("input", found);
          }
        })
        .catch(error => {
          console.log(error);
          //that.errored = true;
        })
        .finally(() => {
          this.loading = false;
          // eslint-disable-next-line
          this.Validate();
        });
    }
  }

  Validate() {
    // eslint-disable-next-line
    return (this.$refs.cpAutocomplete as any).validate();
  }

  onClearClick() {
    console.log("cleared");
    this.$emit("click:clear");
  }
}
