import SeedOrder from "@/types/SeedOrder/SeedOrder";
import UseCaseResponse from "@/types/UseCaseResponse";
import { BaseApiService, axiosApi } from "./BaseApiService";
import SearchSeedOrderPagination from "@/types/SeedOrder/SearchSeedOrderPagination";
import Guid from "@/types/Guid";
import { AxiosError } from "axios";

export default class SeedOrderApiService extends BaseApiService {
  async CreateSeedOrder(order: SeedOrder): Promise<UseCaseResponse<string>> {
    return axiosApi
      .post("/api/tomatosphere/SeedOrder", order)
      .then(resp => resp.data);
  }

  async GetSeedOrdersForSubject(): Promise<UseCaseResponse<SeedOrder[]>> {
    return axiosApi.get("/api/tomatosphere/SeedOrder").then(resp => resp.data);
  }

  SearchOrders(
    payload: SearchSeedOrderPagination
  ): Promise<UseCaseResponse<SearchSeedOrderPagination>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .get("/api/tomatosphere/SeedOrderSearch", {
          params: {
            SearchTerm: payload.SearchTerm,
            CurrentPage: payload.CurrentPage,
            LastPage: payload.LastPage,
            Total: payload.Total,
            PageSize: payload.PageSize,
            AscendingOrder: payload.AscendingOrder,
            SortProp: payload.SortProp,
            SeedYearIds: payload.SeedYearIds
          }
        })
        .then(response => {
          resolve(response.data as UseCaseResponse<SearchSeedOrderPagination>);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  DeleteOrder(id: Guid): Promise<UseCaseResponse<string>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SeedOrder/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error as AxiosError);
        });
    });
  }

  UpdateOrder(order: SeedOrder): Promise<UseCaseResponse<boolean>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/api/tomatosphere/SeedOrder/${order.Id}`, order)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
