import { render, staticRenderFns } from "./SeedYearInfo.vue?vue&type=template&id=151a77bc&scoped=true&"
import script from "./SeedYearInfo.vue?vue&type=script&lang=ts&"
export * from "./SeedYearInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151a77bc",
  null
  
)

export default component.exports