
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class NumberSelect extends Vue {
  @Prop({ default: 0 }) numOfItems!: number;
  @Prop() label!: string;
  @Prop({ default: "" }) hint!: string;
  @Prop() rules: Array<Function> | undefined;
  @Prop({ default: 0 }) value!: number;

  get items() {
    return Array(this.numOfItems)
      .fill(0)
      .map((_, idx) => idx + 1);
  }

  @Emit()
  onInput() {
    return this.value;
  }
}
