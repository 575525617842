
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ProvinceSelect extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) rules: Array<Function> | undefined;

  province = this.value || "";

  provinces: object[] = [
    { value: "Alberta", text: this.$root.$t("Alberta") },
    { value: "British Columbia", text: this.$root.$t("British Columbia") },
    { value: "Manitoba", text: this.$root.$t("Manitoba") },
    { value: "New Brunswick", text: this.$root.$t("New Brunswick") },
    {
      value: "Newfoundland and Labrador",
      text: this.$root.$t("Newfoundland and Labrador")
    },
    {
      value: "Northwest Territories",
      text: this.$root.$t("Northwest Territories")
    },
    { value: "Nova Scotia", text: this.$root.$t("Nova Scotia") },
    { value: "Nunavut", text: this.$root.$t("Nunavut") },
    { value: "Ontario", text: this.$root.$t("Ontario") },
    {
      value: "Prince Edward Island",
      text: this.$root.$t("Prince Edward Island")
    },
    { value: "Quebec", text: this.$root.$t("Quebec") },
    { value: "Saskatchewan", text: this.$root.$t("Saskatchewan") },
    { value: "Yukon", text: this.$root.$t("Yukon") }
  ];

  @Watch("value")
  onValueChanged() {
    this.province = this.value || "";
  }

  onInput() {
    this.$emit("input", this.province);
  }
}
