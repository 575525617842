
import { Component, Vue } from "vue-property-decorator";
import SubmissionModule from "@/modules/submission";
import { getModule } from "vuex-module-decorators";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import Swal, { SweetAlertOptions } from "sweetalert2";
import SubmissionGroupLabels from "@/components/submission-group-labels/SubmissionGroupLabels.vue";
@Component({
  components: { SubmissionGroupLabels }
})
export default class EditSubmissionGroup extends Vue {
  submissionState = getModule(SubmissionModule, this.$store);
  apiService: SubmissionApiService = new SubmissionApiService();

  get submissionGroup() {
    return this.submissionState.SelectedSubmissionGroup!;
  }

  get nameRules() {
    return [() => this.submissionGroup && this.submissionGroup.Name !== ""];
  }

  cancel() {
    this.submissionState.resetSelectedSubmissionGroup();
    this.navigateToSearchPage();
  }

  update() {
    this.apiService
      .UpdateSubmission(this.submissionGroup!)
      .then(response => {
        toastMessage({
          message: this.$t(response.Message) as string,
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedGroupUpdate") as string,
          type: "error"
        });
      });
  }

  navigateToSearchPage() {
    this.$router.push({ name: "SubmissionGroups" });
  }

  delete() {
    this.apiService
      .DeleteSubmission(this.submissionGroup!.Id)
      .then(response => {
        this.navigateToSearchPage();
        toastMessage({
          message: this.$t(response.Message) as string,
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedGroupDelete") as string,
          type: "error"
        });
      });
  }

  confirmDelete() {
    Swal.fire({
      title: this.$t("AreYouSure"),
      text: this.$t("ThisActionWillDeleteTheSubmissionGroup"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.$t("Delete") as string,
      confirmButtonColor: "#c62828",
      cancelButtonText: this.$t("Cancel") as string
    } as SweetAlertOptions).then(result => {
      if (result.isConfirmed && this.submissionGroup) {
        this.delete();
      }
    });
  }

  mounted() {
    if (!this.submissionState.SelectedSubmissionGroup)
      this.navigateToSearchPage();
  }
}
