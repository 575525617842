import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify, {
  iconfont: "mdi"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0054a4", //lts blue
        secondary: "#162E7D", //tomatosphere blue
        accent: "#ec2627", //ts red,
        error: "#dc3545" //bootstrap's error color
      }
    }
  }
});
