
import { Component, Emit, Vue } from "vue-property-decorator";
import AddSubmissionGroupModal from "@/components/modals/AddSubmissionGroupModal.vue";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import SearchInput from "@/components/search/SearchInput.vue";

@Component({
  components: { SearchInput, AddSubmissionGroupModal }
})
export default class SubmissionGroupNavigation extends Vue {
  submissionApiService = new SubmissionApiService();
  searchTerm = "";

  @Emit()
  searchSubmissionGroups(value: string) {
    return value;
  }

  @Emit()
  searching() {
    return;
  }

  createGroup(submissionGroup: SubmissionGroup) {
    this.submissionApiService
      .CreateSubmissionGroup(submissionGroup)
      .then(response => {
        this.searchSubmissionGroups(this.searchTerm);
        toastMessage({
          message: this.$t(response.Message) as string,
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedGroupCreate") as string,
          type: "error"
        });
      });
  }
}
