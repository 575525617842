import BaseEntity from "../BaseEntity";
import Guid from "../Guid";

export default class SeedOrderShippingInfo extends BaseEntity {
  SeedOrderId = Guid.Empty;
  Addresse = "";
  CompanyName = "";
  City = "";
  Country = "";
  RegionName = "";
  RegionCode = "";
  BuildingNumber = "";
  SubBuilding = "";
  Street = "";
  Line1 = "";
  Line2 = "";
  PostalCode = "";
}
