import BaseEntity from "../BaseEntity";
import Guid from "../Guid";
import SeedYear from "../SeedYear/SeedYear";
import AuxOrganization from "../AuxOrganization";
import SeedOrderClass from "./SeedOrderClass";
import SeedOrderShippingInfo from "./SeedOrderShippingInfo";
import Organization from "@/types/Organization.ts";

export default class SeedOrder extends BaseEntity {
  UserId = Guid.Empty;
  OrganizationId = "";
  OrganizationName = "";
  Organization: Organization | undefined;
  NumEducators = 0;
  NumClasses = 0;
  SeedYearId = Guid.Empty;
  SeedOrderShippingInfoId = Guid.Empty;
  AuxOrganizationId = "";
  SeedYear: SeedYear = new SeedYear();
  AuxOrganization: AuxOrganization | undefined;
  SeedOrderShippingInfo: SeedOrderShippingInfo = new SeedOrderShippingInfo();
  SeedOrderClasses: SeedOrderClass[] = [];
  Locale = "";
}
