import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import SeedOrderApiService from "@/services/SeedOrderApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import SearchSeedOrderPagination from "@/types/SeedOrder/SearchSeedOrderPagination";
import { AxiosError } from "axios";
import { ApiErrorResponse } from "@/types/Interfaces/ApiResponse";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import DataObject from "@/types/DataObject";
import { i18n } from "@/i18n";
import AuxOrganization from "@/types/AuxOrganization";
import SeedYear from "@/types/SeedYear/SeedYear";

@Module({ namespaced: true, name: "order" })
class Order extends VuexModule {
  OrderPagination = new SearchSeedOrderPagination();
  SeedOrderApiService = new SeedOrderApiService();
  IsSearching = true;
  selectedOrder: SeedOrder | undefined = undefined;

  /**
   * @Getters
   */
  get SearchTerm() {
    return this.OrderPagination.SearchTerm;
  }

  get CurrentPage() {
    return this.OrderPagination.CurrentPage;
  }

  get LastPage() {
    return this.OrderPagination.LastPage;
  }

  get Orders() {
    return this.OrderPagination.Value;
  }

  get SelectedOrder() {
    return this.selectedOrder;
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_SEARCH_TERM(searchTerm: string): void {
    this.OrderPagination.SearchTerm = searchTerm;
  }

  @Mutation
  SET_ORDERS(orders: Array<SeedOrder>) {
    this.OrderPagination.Value = orders;
  }

  @Mutation
  SET_ORDER_PAGINATION(OrderPagination: SearchSeedOrderPagination) {
    this.OrderPagination = OrderPagination;
  }

  @Mutation
  SET_IS_SEARCHING(value: boolean) {
    this.IsSearching = value;
  }

  @Mutation
  SET_CURRENT_PAGE(value: number) {
    this.OrderPagination.CurrentPage = value;
  }

  @Mutation
  INCREMENT_PAGE() {
    this.OrderPagination.CurrentPage += 1;
  }

  @Mutation
  DECREMENT_PAGE() {
    this.OrderPagination.CurrentPage -= 1;
  }

  @Mutation
  SET_SELECTED_ORDER(value: SeedOrder) {
    this.selectedOrder = value;
  }

  @Mutation
  SET_SELECTED_ORDER_ORGANIZATION(id: string) {
    if (this.selectedOrder) {
      this.selectedOrder.OrganizationId = id;
    }
  }

  @Mutation
  SET_SELECTED_ORDER_EDUCATOR_NUMBER(value: number) {
    if (this.selectedOrder) {
      this.selectedOrder.NumEducators = value;
    }
  }

  @Mutation
  SET_SELECTED_ORDER_SHIPPING_INFO(value: DataObject) {
    if (!this.selectedOrder || !this.selectedOrder.SeedOrderShippingInfo)
      return;
    this.selectedOrder.SeedOrderShippingInfo.Line1 = value.Line1;
    this.selectedOrder.SeedOrderShippingInfo.Line2 = value.Line2;
    this.selectedOrder.SeedOrderShippingInfo.Street = value.Street;
    this.selectedOrder.SeedOrderShippingInfo.City = value.City;
    this.selectedOrder.SeedOrderShippingInfo.PostalCode = value.PostalCode;
    this.selectedOrder.SeedOrderShippingInfo.RegionName = value.ProvinceName;
    this.selectedOrder.SeedOrderShippingInfo.RegionCode = value.ProvinceCode;
    this.selectedOrder.SeedOrderShippingInfo.Country = value.CountryName;
    this.selectedOrder.SeedOrderShippingInfo.SubBuilding = value.SubBuilding;
    this.selectedOrder.SeedOrderShippingInfo.BuildingNumber =
      value.BuildingNumber;
  }

  @Mutation
  SET_SELECTED_ORDER_AUX_ORGANIZATION(value: AuxOrganization) {
    if (!this.selectedOrder) {
      return;
    }

    if (!this.selectedOrder.AuxOrganization) {
      this.selectedOrder.AuxOrganization = new AuxOrganization();
    }

    this.selectedOrder.AuxOrganization.StreetAddress = value.StreetAddress;
    this.selectedOrder.AuxOrganization.Region = value.Region;
    this.selectedOrder.AuxOrganization.Name = value.Name;
    this.selectedOrder.AuxOrganization.City = value.City;
    this.selectedOrder.AuxOrganization.PostalCode = value.PostalCode;
  }

  @Mutation
  SET_SELECTED_ORDER_AUX_ORGANIZATION_ID(value: string) {
    if (!this.selectedOrder) return;
    this.selectedOrder.AuxOrganizationId = value;
  }

  @Mutation
  SET_SELECTED_ORDER_SEED_YEAR(value: SeedYear) {
    if (!this.selectedOrder) return;
    this.selectedOrder.SeedYearId = value.Id;
    this.selectedOrder.SeedYear = new SeedYear(
      value.IsActive,
      value.StartDate,
      value.EndDate,
      value.SeedYearSubmissionGroups,
      value.SeedYearLocales,
      value.Id,
      value.Deleted
    );
  }

  @Mutation
  SET_SORT_PROPERTY(sortProperty: string) {
    this.OrderPagination.SortProp = sortProperty;
  }

  @Mutation
  SET_ASCENDING_ORDER(sortOrder: boolean) {
    this.OrderPagination.AscendingOrder = sortOrder;
  }

  @Mutation
  SET_SEARCH_SEED_YEAR_IDS(ids: string) {
    this.OrderPagination.SeedYearIds = ids;
  }

  /**
   * @Actions
   */
  @Action
  UpdateSearchTerm(searchTerm: string) {
    this.context.commit("SET_SEARCH_TERM", searchTerm);
    this.context.commit("SET_CURRENT_PAGE", 1);
    this.context.dispatch("SearchOrders");
  }

  @Action
  UpdateIsSearching(value: boolean) {
    this.context.commit("SET_IS_SEARCHING", value);
  }

  @Action
  UpdateOrders(order: SeedOrder): void {
    this.context.commit("AddOrder", order);
  }

  @Action
  DeleteOrder(orderId: string): void {
    this.SeedOrderApiService.DeleteOrder(orderId)
      .then(data => {
        this.context.dispatch("SearchOrders");
        toastMessage({
          message: data.Message,
          type: "success"
        });
      })
      .catch((error: AxiosError<ApiErrorResponse>) => {
        toastMessage({
          message: error.response!.data.Message,
          type: "error"
        });
        console.error(error);
      });
  }

  @Action
  NextPage() {
    this.context.commit("INCREMENT_PAGE");
    this.context.dispatch("SearchOrders");
  }

  @Action
  PrevPage() {
    this.context.commit("DECREMENT_PAGE");
    this.context.dispatch("SearchOrders");
  }

  @Action
  SearchOrders() {
    this.SeedOrderApiService.SearchOrders(this.OrderPagination)
      .then((data: UseCaseResponse<SearchSeedOrderPagination>) => {
        this.context.commit("SET_IS_SEARCHING", false);
        this.context.commit("SET_ORDER_PAGINATION", data.Value);
      })
      .catch(error => {
        this.context.commit("SET_IS_SEARCHING", false);
        toastMessage({
          message: "Unable to search",
          type: "error"
        });
        console.error(error);
      });
  }

  @Action
  UpdateOrder() {
    if (!this.SelectedOrder) {
      toastMessage({
        message: i18n.t("FailedOrderUpdate") as string,
        type: "error"
      });
      return;
    }

    this.SeedOrderApiService.UpdateOrder(this.SelectedOrder)
      .then(data => {
        this.context.commit("SET_IS_SEARCHING", true);
        toastMessage({
          message: i18n.t(data.Message) as string,
          type: "success"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  FetchOrder(id: string) {
    const [orderToUpdate] = this.OrderPagination.Value.filter(
      so => so.Id === id
    );
    this.context.commit("SET_SELECTED_ORDER", orderToUpdate);
    this.context.commit("SET_SELECTED_ORDER_SEED_YEAR", orderToUpdate.SeedYear);
  }

  @Action
  SetSelectedOrderOrganization(id: string) {
    this.context.commit("SET_SELECTED_ORDER_ORGANIZATION", id);
  }

  @Action
  SetSelectedOrderEducatorNumber(value: number) {
    this.context.commit("SET_SELECTED_ORDER_EDUCATOR_NUMBER", value);
  }

  @Action
  SetSelectedOrderShippingInfo(address: DataObject) {
    this.context.commit("SET_SELECTED_ORDER_SHIPPING_INFO", address);
  }

  @Action
  SetSelectedOrder(value: SeedOrder) {
    this.context.commit("SET_SELECTED_ORDER", value);
    this.context.commit("SET_SELECTED_ORDER_SEED_YEAR", value.SeedYear);
  }

  @Action
  SetSelectedOrderAuxOrganization(value: DataObject) {
    this.context.commit("SET_SELECTED_ORDER_AUX_ORGANIZATION", value);
  }

  @Action
  SetSelectedOrderSeedYear(value: SeedYear) {
    this.context.commit("SET_SELECTED_ORDER_SEED_YEAR", value);
  }

  @Action
  ResetSelectedOrder() {
    this.context.commit("SET_SELECTED_ORDER", undefined);
  }

  @Action
  SetSearchSorting(sortData: {
    sortProperty: string;
    isAscendingOrder: boolean;
  }) {
    this.context.commit("SET_SORT_PROPERTY", sortData.sortProperty);
    this.context.commit("SET_ASCENDING_ORDER", sortData.isAscendingOrder);
    this.context.commit("SET_IS_SEARCHING", true);
    this.context.dispatch("SearchOrders");
  }

  @Action
  SetSearchSeedYearIds(ids: string) {
    this.context.commit("SET_SEARCH_SEED_YEAR_IDS", ids);
    this.context.commit("SET_IS_SEARCHING", true);
    this.context.dispatch("SearchOrders");
  }
}

export default Order;
