
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import TSEnumerationLocale from "@/types/EnumerationLocale";
import Guid from "@/types/Guid";
import NumberInput from "@/components/fields/NumberInput.vue";

@Component({
  components: { NumberInput }
})
export default class EditGradeModal extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: () => new SeedOrderClassGrade() })
  value!: SeedOrderClassGrade;
  @Prop({ default: 1 }) minNumberOfStudents!: number;
  @Prop({ default: 32 }) maxNumberOfStudents!: number;
  @Prop({ default: [] }) grades!: Array<TSEnumerationLocale>;
  @Prop({ default: [] }) selectedGrades!: Array<SeedOrderClassGrade>;

  isFormValid = false;
  oldState = new SeedOrderClassGrade();

  get numberInputHint() {
    return (this.$t("Hint_NumberInput") as string)
      .replace("NUM1", this.minNumberOfStudents.toString())
      .replace("NUM2", this.maxNumberOfStudents.toString());
  }

  get CurrentGrade() {
    const foundGrade = this.grades.find(item => item.Id === this.value.GradeId);
    return foundGrade ? foundGrade : new TSEnumerationLocale();
  }

  set CurrentGrade(value: TSEnumerationLocale) {
    this.value.GradeId = value.Id;
  }

  get AvailableGrades() {
    return this.grades.filter(
      g =>
        !this.selectedGrades.some(
          usedGrade =>
            usedGrade.GradeId === g.Id &&
            usedGrade.GradeId !== this.value.GradeId
        )
    );
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      this.oldState = JSON.parse(JSON.stringify(this.value));
    }
  }

  gradeSelectRules() {
    return [
      () =>
        this.value.GradeId !== Guid.Empty &&
        this.grades.find(item => item.Id === this.value.GradeId) !== undefined
    ];
  }

  numberOfStudentInputRules() {
    return [
      () =>
        this.value.NumStudents >= this.minNumberOfStudents &&
        this.value.NumStudents <= this.maxNumberOfStudents
    ];
  }

  itemText(value: TSEnumerationLocale) {
    return value.Text;
  }

  itemValue(value: TSEnumerationLocale) {
    return value;
  }

  update() {
    (this.$refs.editGradeModalForm as HTMLFormElement).validate();
    if (this.isFormValid) {
      this.$emit("update", this.value);
      this.toggle();
    }
  }

  cancel() {
    this.value.GradeId = this.oldState.GradeId;
    this.value.NumStudents = this.oldState.NumStudents;
    this.toggle();
  }

  toggle() {
    this.$emit("toggle");
  }
}
