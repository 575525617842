
import { Component, Vue, Prop } from "vue-property-decorator";
import SeedLabelStatistics from "@/types/SeedYear/SeedLabelStatistics";
import LabelGerminatePercentage from "@/components/analytics/LabelGerminatePercentage.vue";
@Component({
  components: { LabelGerminatePercentage }
})
export default class GerminationPercentage extends Vue {
  @Prop({ default: () => [] }) stats!: Array<SeedLabelStatistics>;
}
