import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("Order Confirmation")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("ReviewOrderText")))]),_c('div',[_vm._v(_vm._s(_vm.seedYear))]),_c('div',[_vm._v(_vm._s(_vm.totalClasses))]),_c('div',[_vm._v(_vm._s(_vm.totalStudents))]),_c('div',[_vm._v(_vm._s(_vm.companyName))]),_c(VCard,{staticClass:"pa-4 my-4"},[_c('h4',[_vm._v(_vm._s(_vm.$t("SeedsShippedTo"))+_vm._s(_vm.$t("Colon")))]),_c('br'),_vm._v(" "+_vm._s(_vm.addresse)+" "),_c('br'),(_vm.shippingCompany)?[_vm._v(" "+_vm._s(_vm.shippingCompany)+" "),_c('br')]:_vm._e(),_vm._v(" "+_vm._s(_vm.street)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.city)+" "+_vm._s(_vm.region)+" "+_vm._s(_vm.postalCode)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.country)+" ")],2),_c(VBtn,{staticClass:"secondary",on:{"click":function($event){_vm.termsAndConditions = true}}},[_vm._v(_vm._s(_vm.$t("TermsAndConditionsLabel")))]),_c(VDialog,{attrs:{"width":"90%","max-width":"500px"},model:{value:(_vm.termsAndConditions),callback:function ($$v) {_vm.termsAndConditions=$$v},expression:"termsAndConditions"}},[_c(VCard,{attrs:{"width":"unset"}},[_c('TermsAndConditions',{on:{"close":function($event){_vm.termsAndConditions = false}}})],1)],1),_c(VCheckbox,{attrs:{"label":_vm.$t('AgreeTermsAndConditions')},on:{"click":_vm.onTermsClick},model:{value:(_vm.termsOk),callback:function ($$v) {_vm.termsOk=$$v},expression:"termsOk"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }