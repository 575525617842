
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ReportApiService from "../services/ReportApiService";
import { getModule } from "vuex-module-decorators";
import authModule from "@/modules/auth";
import Logo from "@/components/Logo.vue";
import parentlogo from "@/components/parentlogo.vue";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("apptitle") as string,
      titleTemplate: this.$root.$t("%s") + " | " + this.$root.$t("apptitle")
    };
  },
  components: {
    Logo,
    parentlogo
  }
})
export default class Nav extends Vue {
  reportService: ReportApiService = new ReportApiService();
  authState = getModule(authModule, this.$store);

  drawer = false;

  getReport(reportName: string) {
    this.reportService.Report(reportName);
  }

  logout() {
    this.authState.Logout();
  }

  login() {
    this.authState.Login();
  }

  get toolbarHeight() {
    let ht = 113;
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      ht = 45;
    }
    return ht;
  }
}
