
import { Component, Prop, Vue } from "vue-property-decorator";
import SeedLabelStatistics from "@/types/SeedYear/SeedLabelStatistics";

@Component
export default class LabelGerminatePercentage extends Vue {
  @Prop({ default: () => new SeedLabelStatistics() })
  label!: SeedLabelStatistics;

  get germinationPercentage() {
    return Math.round((this.label.Germinated / this.label.Planted) * 100);
  }

  get name() {
    return `${this.label.SeedLabel.SeedPackLabel} ${this.treatedText}`;
  }

  get treatedText() {
    return this.label.SeedLabel.Treatment ? `(${this.$t("Treated")})` : "";
  }
}
