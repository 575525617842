import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { axiosCanadaPostApi } from "@/services/BaseApiService";
import { CanadaPostAddressItem } from "@/types/CanadaPostAddressLookup/CanadaPostAddressItem";

@Module({ namespaced: true, name: "canadaPostAddressLookup" })
export default class CanadaPostAddressLookup extends VuexModule {
  API_KEY = "TM73-RB34-RJ36-CD59";
  findUri = "/AddressComplete/Interactive/Find/v2.10/json3.ws";
  retrieveUri = "/AddressComplete/Interactive/Retrieve/v2.11/json3.ws";
  apiParams: {
    Key: string;
    SearchTerm: string;
    SearchFor: string;
    Country: "CAN";
    LanguagePreference: string;
    MaxSuggestions: number;
    MaxResults: number;
    LastId: string;
  } = {
    Key: this.API_KEY,
    SearchTerm: "",
    SearchFor: "Everything",
    Country: "CAN",
    LanguagePreference: "",
    MaxSuggestions: 10,
    MaxResults: 500,
    LastId: ""
  };
  isLoading = false;
  searchTerm = "";
  items: CanadaPostAddressItem[] = [];
  selectedItem: CanadaPostAddressItem = new CanadaPostAddressItem(
    "",
    "",
    "",
    "",
    ""
  );

  /**
   * @Getters
   */
  get IsLoading() {
    return this.isLoading;
  }

  get SearchTerm() {
    return this.searchTerm;
  }

  get Items() {
    return this.items;
  }

  get SelectedItem() {
    return this.selectedItem;
  }

  get RetrieveUri() {
    return this.retrieveUri;
  }

  get FindUri() {
    return this.findUri;
  }

  get ApiKey() {
    return this.API_KEY;
  }

  get ApiParams() {
    return this.apiParams;
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_IS_LOADING(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  SET_SEARCH_TERM(value: string) {
    this.searchTerm = value;
  }

  @Mutation
  SET_ITEMS(values: CanadaPostAddressItem[]) {
    this.items = values;
  }

  @Mutation
  SET_SELECTED_ITEM(value: CanadaPostAddressItem) {
    this.selectedItem = value;
  }

  /**
   * @Actions
   */
  @Action
  SetIsLoading(value: boolean) {
    this.context.commit("SET_IS_LOADING", value);
  }

  @Action
  SetSearchTerm(value: string) {
    this.context.commit("SET_SEARCH_TERM", value);
    this.context.commit("SET_IS_LOADING", true);
  }

  @Action
  SetSelectedItem(value: CanadaPostAddressItem) {
    this.context.commit(
      "SET_SELECTED_ITEM",
      value ? value : new CanadaPostAddressItem("", "", "", "", "")
    );
  }

  @Action
  FetchAddresses() {
    axiosCanadaPostApi
      .get(this.findUri, {
        params: {
          ...this.apiParams,
          SearchTerm: this.searchTerm
        }
      })
      .then(response => {
        this.context.commit("SET_ITEMS", response.data.Items);
        this.context.commit("SET_IS_LOADING", false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  FetchAddressByAddress(address: string) {
    axiosCanadaPostApi
      .get(this.findUri, {
        params: {
          ...this.apiParams,
          SearchTerm: address
        }
      })
      .then(response => {
        this.context.commit("SET_ITEMS", response.data.Items);
        this.context.commit("SET_IS_LOADING", false);
        if (response.data.Items.length > 0)
          this.context.commit("SET_SELECTED_ITEM", response.data.Items[0]);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
