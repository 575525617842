import { RouteConfig } from "vue-router";
import SubmissionGroups from "@/views/admin/SubmissionGroups/SubmissionGroups.vue";
import EditSubmissionGroup from "@/views/admin/SubmissionGroups/EditSubmissionGroup.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Admin/SubmissionGroups",
    name: "SubmissionGroups",
    component: SubmissionGroups
  },
  {
    path: "/Admin/SubmissionGroups/Edit",
    name: "EditSubmissionGroup",
    component: EditSubmissionGroup
  }
];

export default routes;
