import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import { i18n } from "@/i18n";

@Module({ namespaced: true, name: "submission" })
export class Submission extends VuexModule {
  selectedSubmissionGroup: SubmissionGroup | undefined = undefined;
  apiService: SubmissionApiService = new SubmissionApiService();

  /**
   * @Getters
   */
  get SelectedSubmissionGroup(): SubmissionGroup | undefined {
    return this.selectedSubmissionGroup;
  }

  get SelectedSubmissionGroupLabels(): Array<SubmissionGroupLabel> {
    return this.selectedSubmissionGroup
      ? this.selectedSubmissionGroup.SubmissionGroupLabels
      : [];
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_SELECTED_SUBMISSION_GROUP(value: SubmissionGroup) {
    this.selectedSubmissionGroup = value;
  }

  @Mutation
  ADD_SUBMISSION_GROUP_LABEL(value: SubmissionGroupLabel) {
    this.selectedSubmissionGroup!.SubmissionGroupLabels.push(value);
  }

  @Mutation
  REMOVE_SUBMISSION_GROUP_LABEL(id: string) {
    if (!this.selectedSubmissionGroup) return;

    this.selectedSubmissionGroup.SubmissionGroupLabels = this.selectedSubmissionGroup.SubmissionGroupLabels.filter(
      item => item.Id !== id
    );
  }

  /**
   * @Actions
   */
  @Action
  setSelectedSubmissionGroup(payload: SubmissionGroup) {
    this.context.commit("SET_SELECTED_SUBMISSION_GROUP", payload);
  }

  @Action
  resetSelectedSubmissionGroup() {
    this.context.commit("SET_SELECTED_SUBMISSION_GROUP", undefined);
  }

  @Action
  addSubmissionGroupLabel(payload: SubmissionGroupLabel) {
    const label = payload.Label;
    this.apiService
      .CreateSubmissionGroupLabel(payload)
      .then(response => {
        response.Value.Label = label;
        this.context.commit("ADD_SUBMISSION_GROUP_LABEL", response.Value);
        toastMessage({
          message: i18n.t(response.Message) as string,
          type: "success"
        });
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: i18n.t(error.response.data.Message) as string,
          type: "error"
        });
      });
  }

  @Action
  removeSubmissionGroupLabel(id: string) {
    this.apiService
      .DeleteSubmissionGroupLabel(id)
      .then(response => {
        this.context.commit("REMOVE_SUBMISSION_GROUP_LABEL", id);
        toastMessage({
          message: i18n.t(response.Message) as string,
          type: "success"
        });
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: i18n.t(error.response.data.Message) as string,
          type: "error"
        });
      });
  }
}

export default Submission;
