
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import orgModule from "@/modules/organization";
import { getModule } from "vuex-module-decorators";
import Organization from "@/types/Organization.ts";
import { debounce } from "lodash";

@Component
export default class OrganizationLookup extends Vue {
  @Prop({ default: "" }) id!: string;
  @Prop({ default: () => [] }) rules!: Array<Function>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  orgState = getModule(orgModule, this.$store);
  public searchOrganizations = debounce(() => {
    this.orgState.FetchOrganizations();
  }, 750);

  get value() {
    return this.orgState.SelectedOrganization;
  }

  set value(value: Organization) {
    this.orgState.SetSelectedOrganization(value);
  }

  get searchTerm() {
    return this.orgState.SearchTerm;
  }

  set searchTerm(updatedSearchTerm: string) {
    if (
      updatedSearchTerm &&
      updatedSearchTerm !== this.organizationDisplayText(this.value)
    ) {
      this.orgState.SetSearchTerm(updatedSearchTerm);
    }
  }

  @Watch("id", { immediate: true, deep: true })
  onIdChange(val: string) {
    if (val == "" || val == null)
      this.orgState.SetSelectedOrganization(new Organization());
  }

  @Emit()
  onInput(item: Organization) {
    console.log("one input");
    this.orgState.SetSelectedOrganization(item);
    return item;
  }

  itemText(item: Organization) {
    return this.organizationDisplayText(item);
  }

  itemValue(item: Organization) {
    return item;
  }

  organizationDisplayText(org: Organization) {
    return `${org.Name} - ${org.Address} (${org.City})`;
  }

  mounted() {
    if (this.id) {
      this.orgState.SetupOrganizationLookup(this.id);
    }
  }
}
