
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SortableHeader extends Vue {
  @Prop({ default: () => "" }) text!: string;
  @Prop({ default: () => "" }) sortProperty!: string;
  state: string | null = null;
  ASCENDING = "ASC";
  DESCENDING = "DESC";
  hover = false;

  get icon(): string {
    if (this.show || this.hover) {
      return this.isAscending || !this.show
        ? "mdi-chevron-up"
        : "mdi-chevron-down";
    }

    return "";
  }

  get show(): boolean {
    return this.state !== null;
  }

  get isAscending(): boolean {
    return this.state === this.ASCENDING;
  }

  toggleSort() {
    if (!this.state) {
      this.state = this.ASCENDING;
    } else if (this.isAscending) {
      this.state = this.DESCENDING;
    } else {
      this.state = null;
    }

    this.$emit("onSort", {
      isAscendingOrder: this.isAscending || !this.state,
      sortProperty: this.show ? this.sortProperty : ""
    });
  }

  toggleHover() {
    this.hover = !this.hover;
  }
}
