import { UserManager, WebStorageStateStore } from "oidc-client";
import { GetConfigForEnvironment } from "@/env-config";

const config = GetConfigForEnvironment();

export default class SecurityService {
  userManager: UserManager;
  isAuthenticated = false;

  constructor() {
    this.userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: config.idUrl,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: "ltsol_ts",
      // eslint-disable-next-line @typescript-eslint/camelcase
      response_type: "code",
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: `${window.location.origin}/static/callback.html`,
      scope: "openid LTSOL_Apis",
      // eslint-disable-next-line @typescript-eslint/camelcase
      post_logout_redirect_uri: window.location.origin + "/index.html",
      // eslint-disable-next-line @typescript-eslint/camelcase
      silent_redirect_uri: `${window.location.origin}/static/silent-renew.html`,
      // eslint-disable-next-line @typescript-eslint/camelcase
      ui_locales: window.location.origin.includes("parlonssciences")
        ? "fr-CA"
        : "en-CA",
      accessTokenExpiringNotificationTime: 10,
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true
    });
    this.userManager.startSilentRenew();
    this.userManager.events.addAccessTokenExpired(() => {
      this.userManager
        .signinSilent()
        .then()
        .catch(error => {
          console.error(error);
        });
    });
  }

  GetAccessToken() {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(user => {
          this.isAuthenticated = user!.access_token !== "";
          resolve(user ? user.access_token : "");
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  SetIsAuthenticated() {
    this.userManager
      .getUser()
      .then(user => {
        this.isAuthenticated = user!.access_token !== undefined;
      })
      .catch(error => {
        console.error(error);
      });
  }

  login() {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(user => {
          if (user == null) {
            this.userManager
              .signinRedirect({ state: window.location.href })
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.userManager
        .signoutRedirect()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
