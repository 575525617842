
import { Component, Vue, Prop } from "vue-property-decorator";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import ClassroomInfo from "@/components/classroom-info/ClassroomInfo.vue";

@Component({
  components: {
    ClassroomInfo
  }
})
export default class ClassroomForm extends Vue {
  @Prop({ default: () => new SeedOrder() }) order!: SeedOrder;

  formValid: boolean | null = null;

  get orderHasClasses() {
    return this.order.SeedOrderClasses.length > 0;
  }

  get classesHaveGrades() {
    const noGrades = this.order.SeedOrderClasses.find(
      c => c.SeedOrderClassGrades.length === 0
    );
    return undefined === noGrades;
  }

  get errorMessages() {
    return [
      !this.orderHasClasses ? this.$t("Rule_Classes") : "",
      !this.classesHaveGrades ? this.$t("Rule_Grades") : ""
    ]
      .filter(msg => msg.length)
      .join(", ");
  }

  get rules() {
    return [() => this.orderHasClasses && this.classesHaveGrades];
  }

  get valid(): boolean {
    return (this.$refs.ClassroomForm as HTMLFormElement).validate();
  }
}
