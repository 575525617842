
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import AuxOrganization from "@/types/AuxOrganization";
import SchoolLookup from "@/components/SchoolLookup.vue";
import AuxOrganizationInfo from "@/components/AuxOrganizationInfo.vue";

@Component({
  components: {
    SchoolLookup,
    AuxOrganizationInfo
  }
})
export default class SchoolInfo extends Vue {
  @Prop(Object) value: SeedOrder | undefined;

  order: SeedOrder = this.value || new SeedOrder();
  auxSchool = false;
  educatorItems: number[] = Array.from(new Array(25), (x, i) => i + 1);
  classroomItems: number[] = Array.from(new Array(10), (x, i) => i + 1);

  @Watch("value")
  onValueChanged() {
    this.order = this.value || new SeedOrder();
  }

  get valid(): boolean {
    return (this.$refs.form as HTMLFormElement).validate();
  }

  onOrganizationInput() {
    this.order.OrganizationId = this.order.Organization
      ? this.order.Organization.Id
      : "";

    this.order.OrganizationName = this.order.Organization
      ? this.order.Organization.Name
      : "";
  }

  resetAuxOrganization() {
    if (this.order.AuxOrganization) {
      this.order.AuxOrganization.Name = "";
      this.order.AuxOrganization.City = "";
      this.order.AuxOrganization.PostalCode = "";
      this.order.AuxOrganization.Region = "";
      this.order.AuxOrganization.StreetAddress = "";
    }
  }

  auxSchoolClick() {
    if (this.auxSchool) {
      this.order.AuxOrganization = new AuxOrganization();
      this.order.OrganizationId = "";
      this.order.OrganizationName = "";
    } else {
      this.resetAuxOrganization();
    }
  }
}
