
import { Component, Vue } from "vue-property-decorator";
import SeedYearApiService from "@/services/SeedYear/SeedYearApiService";
import SeedYear from "@/types/SeedYear/SeedYear";
import SeedYearInfo from "@/components/SeedYearInfo.vue";
import AddYearLabel from "@/components/modals/AddYearLabel.vue";
import YearLabelList from "@/components/year-label-list-view/YearLabelList.vue";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import Swal, { SweetAlertOptions } from "sweetalert2";
import AddSubmissionGroup from "@/components/modals/AddSubmissionGroup.vue";
import SeedYearSubmissionGroup from "@/types/SeedYear/SeedYearSubmissionGroup";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";

@Component({
  components: {
    SeedYearInfo,
    AddYearLabel,
    YearLabelList,
    AddSubmissionGroup,
    DeleteIcon
  }
})
export default class EditYear extends Vue {
  seedYear = new SeedYear();
  yearApiService = new SeedYearApiService();
  isFormValid = false;

  get AlreadySelectedGroups() {
    return this.seedYear.SeedYearSubmissionGroups.map(
      item => item.SubmissionGroupId
    );
  }

  getLabels(item: SubmissionGroup | undefined) {
    if (item !== undefined && item.SubmissionGroupLabels !== undefined) {
      return item.SubmissionGroupLabels.map(
        (x: SubmissionGroupLabel) => x.Label.SeedPackLabel
      ).join();
    } else {
      return "";
    }
  }

  mounted() {
    const id: string = this.$route.query.id as string;
    this.yearApiService.GetSeedYearById(id).then(resp => {
      this.seedYear = resp.Value;
    });
  }

  deleteYear() {
    Swal.fire({
      title: this.$t("AreYouSure"),
      text: this.$t("ThisActionWillDeleteTheYear"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.$t("Delete") as string,
      confirmButtonColor: "#c62828",
      cancelButtonText: this.$t("Cancel") as string
    } as SweetAlertOptions).then(result => {
      if (result.isConfirmed) {
        this.yearApiService
          .DeleteSeedYear(this.seedYear.Id)
          .then(response => {
            toastMessage({
              message: response.Success
                ? (this.$t("SeasonDeleted") as string)
                : (this.$t("FailedSeasonDelete") as string),
              type: response.Success ? "success" : "error"
            });
            if (response.Success) {
              this.$router.push("/Admin/SeedYear");
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  }

  update() {
    (this.$refs.SeedYearEditForm as HTMLFormElement).validate();
    if (!this.isFormValid) {
      console.log("form is invalid");
      console.log(this.seedYear);
      return;
    }
    this.yearApiService
      .UpdateSeedYear(this.seedYear)
      .then(response => {
        toastMessage({
          message: response.Success
            ? (this.$t("SeasonUpdated") as string)
            : (this.$t("FailedSeasonUpdate") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  truncate(value: string) {
    const cutOffPoint = 25;
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  addSeedYearSubmissionGroup(value: SeedYearSubmissionGroup) {
    this.seedYear.SeedYearSubmissionGroups.push(value);
  }

  removeSeedYearSubmissionGroup(sysg: SeedYearSubmissionGroup) {
    const removalIndex = this.seedYear.SeedYearSubmissionGroups.indexOf(sysg);
    this.seedYear.SeedYearSubmissionGroups.splice(removalIndex, 1);
  }

  cancel() {
    this.$router.push("/Admin/SeedYear");
  }
}
