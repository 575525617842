
import { Component, Vue } from "vue-property-decorator";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";

@Component
export default class AddSubmissionGroupModal extends Vue {
  show = false;
  IsFormValid = false;
  value = new SubmissionGroup();

  get nameRules() {
    return [() => this.value.Name !== ""];
  }

  toggle() {
    this.show = !this.show;
  }

  reset() {
    this.value = new SubmissionGroup();
  }

  cancel() {
    this.reset();
    this.toggle();
  }

  add() {
    (this.$refs.AddSubmissionGroupModalForm as HTMLFormElement).validate();
    if (this.IsFormValid) {
      this.$emit("add", this.value);
      this.toggle();
      this.reset();
    }
  }
}
