import SeedLabelSubmissionHeader from "@/types/SeedLabelSubmission/SeedLabelSubmissionHeader";
import UseCaseResponse from "@/types/UseCaseResponse";
import { BaseApiService, axiosApi } from "../BaseApiService";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SearchSubmissionGroupPagination from "@/types/SeedLabelSubmission/SearchSubmissionGroupPagination";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";
import SubmissionStatistics from "@/types/SeedLabelSubmission/SubmissionStatistics";
import { AxiosResponse } from "axios";

export default class SubmissionApiService extends BaseApiService {
  async CreateSubmissionHeader(
    header: SeedLabelSubmissionHeader
  ): Promise<UseCaseResponse<string>> {
    return axiosApi
      .post(`/api/tomatosphere/SubmissionHeader`, header)
      .then(resp => resp.data);
  }

  async CreateSubmissionGroup(
    submissionGroup: SubmissionGroup
  ): Promise<UseCaseResponse<SubmissionGroup>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .post(`/api/tomatosphere/SubmissionGroup`, submissionGroup)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async SearchSubmissionGroup(
    payload: SearchSubmissionGroupPagination
  ): Promise<UseCaseResponse<SearchSubmissionGroupPagination>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/api/tomatosphere/SubmissionGroup`, {
          params: {
            SearchTerm: payload.SearchTerm,
            CurrentPage: payload.CurrentPage,
            LastPage: payload.LastPage,
            Total: payload.Total,
            PageSize: payload.PageSize,
            AscendingOrder: payload.AscendingOrder,
            SortProp: payload.SortProp
          }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async UpdateSubmission(
    payload: SubmissionGroup
  ): Promise<UseCaseResponse<boolean>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/api/tomatosphere/SubmissionGroup`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async DeleteSubmission(id: string): Promise<UseCaseResponse<boolean>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SubmissionGroup/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async CreateSubmissionGroupLabel(
    payload: SubmissionGroupLabel
  ): Promise<UseCaseResponse<SubmissionGroupLabel>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .post(`/api/tomatosphere/SubmissionGroupLabel`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async DeleteSubmissionGroupLabel(
    id: string
  ): Promise<UseCaseResponse<boolean>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SubmissionGroupLabel/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async GetSubmissionByHeaderId(
    id: string
  ): Promise<UseCaseResponse<SeedLabelSubmissionHeader>> {
    return axiosApi
      .get(`/api/tomatosphere/SubmissionHeader/${id}`)
      .then(resp => resp.data);
  }

  async GetSubmissionStatisticsBySubmissionGroupId(
    id: string
  ): Promise<UseCaseResponse<SubmissionStatistics>> {
    return axiosApi
      .get(`/api/tomatosphere/SubmissionGroup/${id}/Statistics`)
      .then(resp => resp.data);
  }

  async GetSubmissionGroupById(
    id: string
  ): Promise<UseCaseResponse<SubmissionGroup>> {
    return axiosApi
      .get(`/api/tomatosphere/SubmissionGroup/${id}`)
      .then(resp => resp.data);
  }

  async GetGroupsByUserSubmittedResults(
    userId: string
  ): Promise<UseCaseResponse<Array<SubmissionGroup>>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(
          `/api/tomatosphere/SubmissionGroup/user/${userId}/submitted/results`
        )
        .then(
          (
            response: AxiosResponse<UseCaseResponse<Array<SubmissionGroup>>>
          ) => {
            resolve(response.data);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }
}
