import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('div',{staticClass:"ml-4",staticStyle:{"max-width":"500px"}},[_c(VTextField,{attrs:{"label":_vm.$t('NameOfSchool'),"rules":[
        () => _vm.auxOrganization.Name.length > 0 || `${_vm.$t('Rule_SchoolName')}`
      ]},model:{value:(_vm.auxOrganization.Name),callback:function ($$v) {_vm.$set(_vm.auxOrganization, "Name", $$v)},expression:"auxOrganization.Name"}}),_c(VTextField,{attrs:{"label":_vm.$t('Address'),"rules":[
        () =>
          _vm.auxOrganization.StreetAddress.length > 0 ||
          `${_vm.$t('Rule_SchoolAddress')}`
      ]},model:{value:(_vm.auxOrganization.StreetAddress),callback:function ($$v) {_vm.$set(_vm.auxOrganization, "StreetAddress", $$v)},expression:"auxOrganization.StreetAddress"}}),_c(VTextField,{attrs:{"label":_vm.$t('City'),"rules":[
        () => _vm.auxOrganization.City.length > 0 || `${_vm.$t('Rule_SchoolCity')}`
      ]},model:{value:(_vm.auxOrganization.City),callback:function ($$v) {_vm.$set(_vm.auxOrganization, "City", $$v)},expression:"auxOrganization.City"}}),_c('ProvinceSelect',{attrs:{"label":_vm.$t('Province'),"rules":[
        () => _vm.auxOrganization.Region.length > 0 || `${_vm.$t('Rule_Province')}`
      ]},model:{value:(_vm.auxOrganization.Region),callback:function ($$v) {_vm.$set(_vm.auxOrganization, "Region", $$v)},expression:"auxOrganization.Region"}}),_c('PostalCodeField',{staticStyle:{"max-width":"175px"},attrs:{"label":_vm.$t('PostalCode'),"rules":[
        () =>
          _vm.auxOrganization.PostalCode.length > 0 ||
          `${_vm.$t('Rule_SchoolPostalCode')}`
      ]},model:{value:(_vm.auxOrganization.PostalCode),callback:function ($$v) {_vm.$set(_vm.auxOrganization, "PostalCode", $$v)},expression:"auxOrganization.PostalCode"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }