
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import { getModule } from "vuex-module-decorators";
import orderModule from "@/modules/order";
import router from "@/router";

@Component
export default class ResultLineItem extends Vue {
  @Prop(Object) order!: SeedOrder;
  orderState = getModule(orderModule, this.$store);
  DEFAULT_VALUE = "N/A";

  get textLimit() {
    return 25;
  }

  get school() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.CompanyName,
      this.DEFAULT_VALUE
    );
  }

  get name() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.Addresse,
      this.DEFAULT_VALUE
    );
  }

  get streetName() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.Line1,
      this.DEFAULT_VALUE
    );
  }

  get city() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.City,
      this.DEFAULT_VALUE
    );
  }

  get region() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.RegionName,
      this.DEFAULT_VALUE
    );
  }

  get postalCode() {
    return this.isEmpty(
      this.order.SeedOrderShippingInfo.PostalCode,
      this.DEFAULT_VALUE
    );
  }

  get organization() {
    if (!this.order.AuxOrganization) return false;

    return this.isEmpty(this.order.AuxOrganization!.Name, this.DEFAULT_VALUE);
  }

  isEmpty(value: string | undefined, defaultVal = ""): string {
    return !value || value.length === 0 ? defaultVal : value;
  }

  truncate(value: string, cutOffPoint: number) {
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  editOrder() {
    this.orderState.FetchOrder(this.order.Id);
    router.push({ name: "EditOrder" });
  }
}
