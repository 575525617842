
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PostalCodeField extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) rules: Array<Function> | undefined;

  strValue = this.value || "";
  /* eslint-disable */
  postalCodeRegex = new RegExp(
    "([ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy][0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvxy])\ ([0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvxy][0-9])"
  );
  /* eslint-enable */

  @Watch("value")
  onValueChanged() {
    this.strValue = this.value || "";
  }

  get AllRules(): Function[] {
    const r: Function[] = this.rules || [];
    r.push(this.PostalCodeValidation);
    return r;
  }

  PostalCodeValidation() {
    const regex = this.postalCodeRegex.test(this.strValue);
    if (!regex) return this.$t("InvalidPostalCode") as string;
    else return true;
  }

  // eslint-disable-next-line
  onInput(val: any) {
    this.$emit("input", this.strValue);
  }
}
