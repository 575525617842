
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SeedOrderShippingInfo from "@/types/SeedOrder/SeedOrderShippingInfo";
import CanadaPostAddressLookup from "@/components/CanadaPostAddressLookup.vue";
import DataObject from "@/types/DataObject";
import ProvinceSelect from "@/components/selects/ProvinceSelect.vue";
import PostalCodeField from "@/components/PostalCodeField.vue";

@Component({
  components: {
    CanadaPostAddressLookup,
    ProvinceSelect,
    PostalCodeField
  }
})
export default class MailingInfo extends Vue {
  @Prop(Object) value: SeedOrderShippingInfo | undefined;

  shippingInfo: SeedOrderShippingInfo =
    this.value || new SeedOrderShippingInfo();

  auxAddress = false;

  get valid(): boolean {
    return (this.$refs.form as HTMLFormElement).validate();
  }

  get ValidMailingAddress(): boolean {
    if (this.auxAddress) return true;
    else {
      return (
        this.shippingInfo.Line1.length > 0 &&
        this.shippingInfo.Street.length > 0 &&
        this.shippingInfo.City.length > 0 &&
        this.shippingInfo.PostalCode.length > 0 &&
        this.shippingInfo.RegionName.length > 0 &&
        this.shippingInfo.RegionCode.length > 0 &&
        this.shippingInfo.Country.length > 0 &&
        this.shippingInfo.BuildingNumber.length > 0
      );
    }
  }

  onAddressSelected(address: DataObject) {
    this.shippingInfo.Line1 = address.Line1;
    this.shippingInfo.Line2 = address.Line2;
    this.shippingInfo.Street = address.Street;
    this.shippingInfo.City = address.City;
    this.shippingInfo.PostalCode = address.PostalCode;
    this.shippingInfo.RegionName = address.ProvinceName;
    this.shippingInfo.RegionCode = address.ProvinceCode;
    this.shippingInfo.Country = address.CountryName;
    this.shippingInfo.SubBuilding = address.SubBuilding;
    this.shippingInfo.BuildingNumber = address.BuildingNumber;
  }

  auxAddressClick() {
    this.resetShippingInfo();
    if (this.auxAddress) this.shippingInfo.Country = "Canada";
  }

  onCPAddressClearClick() {
    if (!this.auxAddress) {
      this.resetShippingInfo();
    }
  }

  resetShippingInfo() {
    this.shippingInfo.Line1 = "";
    this.shippingInfo.Line2 = "";
    this.shippingInfo.Street = "";
    this.shippingInfo.City = "";
    this.shippingInfo.PostalCode = "";
    this.shippingInfo.RegionName = "";
    this.shippingInfo.RegionCode = "";
    this.shippingInfo.Country = "";
    this.shippingInfo.SubBuilding = "";
    this.shippingInfo.BuildingNumber = "";
  }
}
