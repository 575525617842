
import { Vue, Component, Prop } from "vue-property-decorator";
import classroomPickerModule from "@/modules/classroomPicker";
import { getModule } from "vuex-module-decorators";
import addGradeModal from "@/components/modals/AddGradeModal.vue";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import GradeItem from "@/components/classroom-picker/GradeItem.vue";
import ClassItem from "@/components/classroom-picker/ClassItem.vue";

@Component({
  components: {
    addGradeModal,
    GradeItem,
    ClassItem
  }
})
export default class ClassroomPicker extends Vue {
  @Prop({ required: true }) classes!: SeedOrderClass[];
  @Prop({ required: true }) seedOrderId!: string;
  @Prop({ default: false }) editable!: boolean;
  state = getModule(classroomPickerModule, this.$store);

  addClass() {
    this.state.AddSeedOrderClass(this.seedOrderId);
  }

  mounted() {
    this.state.FetchGrades();
    this.state.SetClasses(this.classes);
  }
}
