import UseCaseResponse from "@/types/UseCaseResponse";
import SeedYear from "@/types/SeedYear/SeedYear";
import { BaseApiService, axiosApi } from "../BaseApiService";
import { AxiosResponse } from "axios";
import { mapToSeedYearInstance } from "@/services/SeedYear/SeedYearClassService";

export default class SeedYearApiService extends BaseApiService {
  async GetSeedYears(): Promise<UseCaseResponse<SeedYear[]>> {
    return axiosApi
      .get(`/api/tomatosphere/SeedYear`)
      .then((resp: AxiosResponse<UseCaseResponse<SeedYear[]>>) => {
        resp.data.Value = mapToSeedYearInstance(resp.data.Value);
        return resp.data;
      });
  }

  async GetSeedYearById(id: string): Promise<UseCaseResponse<SeedYear>> {
    return axiosApi
      .get(`/api/tomatosphere/SeedYear/${id}`)
      .then((resp: AxiosResponse<UseCaseResponse<SeedYear>>) => {
        const year = new SeedYear();
        year.copy(resp.data.Value);
        resp.data.Value = year;
        return resp.data;
      });
  }

  async CreateSeedYear(year: SeedYear): Promise<UseCaseResponse<string>> {
    return axiosApi
      .post(`/api/tomatosphere/SeedYear`, year)
      .then(resp => resp.data);
  }

  async UpdateSeedYear(year: SeedYear): Promise<UseCaseResponse<boolean>> {
    return axiosApi
      .put(`/api/tomatosphere/SeedYear/${year.Id}`, year)
      .then(resp => resp.data);
  }

  async DeleteSeedYear(id: string): Promise<UseCaseResponse<boolean>> {
    return axiosApi
      .delete(`/api/tomatosphere/SeedYear/${id}`)
      .then(resp => resp.data);
  }
}
