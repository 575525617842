
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DatePicker extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  localString = "en-CA"; //needs to be implemented
  dateVal = ""; //new Date().toISOString().substr(0, 10);
  dtPicker = false;

  @Watch("value")
  onValueChanged() {
    this.dateVal = this.value
      ? this.value.substr(0, 10)
      : new Date().toISOString().substr(0, 10);
    this.$emit("input", this.dateVal);
  }

  mounted() {
    this.onValueChanged();
  }

  get date() {
    return this.dateVal;
  }

  set date(val: string) {
    if (val.length === 10) {
      this.dateVal = val;
      this.$emit("input", this.dateVal);
    }
  }
}
