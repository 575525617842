import { RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Home from "@/views/Home.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Dashboard", // this will be the home page in a future release
    name: "Dashboard",
    component: Dashboard
  }
];

export default routes;
