
import { Component, Vue, Watch } from "vue-property-decorator";
import LabelChartStatistics from "@/components/analytics/LabelChartStatistics.vue";
import SubmissionGroupRadioGroup from "@/components/SubmissionGroupRadioGroup.vue";
import SeedLabelStatistics from "@/types/SeedYear/SeedLabelStatistics";
import { securityService } from "@/services/BaseApiService";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";

@Component({
  components: {
    LabelChartStatistics,
    SubmissionGroupRadioGroup
  }
})
export default class LabelStatisticWidget extends Vue {
  apiService = new SubmissionApiService();
  userId = "";
  isLoading = false;
  submissionGroups: Array<SubmissionGroup> = [];
  selectedGroup: SubmissionGroup = new SubmissionGroup();
  labelStats: Array<SeedLabelStatistics> = [];

  get noResults() {
    return !this.isLoading && !this.submissionGroups.length;
  }

  get hasStats() {
    return !this.isLoading && this.submissionGroups.length;
  }

  get showGraph() {
    return this.labelStats.length;
  }

  @Watch("selectedGroup")
  onSelectedGroupChange() {
    this.fetchSubmissionStats();
  }

  mounted() {
    this.fetchUser();
  }

  fetchUser() {
    this.toggleLoading();
    securityService.userManager
      .getUser()
      .then(response => {
        if (response && response.profile) {
          this.userId = response.profile.sub;
          this.fetchSubmissionGroups();
        }
        this.toggleLoading();
      })
      .catch(error => {
        this.toggleLoading();
        console.error(error);
      });
  }

  fetchSubmissionGroups() {
    this.apiService
      .GetGroupsByUserSubmittedResults(this.userId)
      .then(response => {
        this.submissionGroups = response.Value;
      })
      .catch(error => {
        console.error(error);
      });
  }

  fetchSubmissionStats() {
    this.toggleLoading();
    this.apiService
      .GetSubmissionStatisticsBySubmissionGroupId(this.selectedGroup.Id)
      .then(response => {
        this.toggleLoading();
        this.labelStats = response.Value.LabelStatistics;
      })
      .catch(error => {
        this.toggleLoading();
        console.error(error);
      });
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }
}
