import Pagination from "@/types/Interfaces/Pagination";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";

export default class SearchSubmissionGroupPagination
  implements Pagination<SubmissionGroup> {
  AscendingOrder: boolean;
  CurrentPage: number;
  LastPage: number;
  PageSize: number;
  SearchTerm: string;
  SortProp: string;
  Total: number;
  Value: Array<SubmissionGroup>;

  constructor() {
    this.CurrentPage = 1;
    this.LastPage = 1;
    this.Total = 0;
    this.PageSize = 20;
    this.Value = [];
    this.SearchTerm = "";
    this.SortProp = "";
    this.AscendingOrder = false;
  }
}
