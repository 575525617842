import BaseEntity from "@/types/BaseEntity";
import SeedYear from "@/types/SeedYear/SeedYear";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import Guid from "@/types/Guid";

export default class YearLabel extends BaseEntity {
  SeedYearId: string = Guid.Empty;
  SeedLabelId: string = Guid.Empty;
  SeedYear: SeedYear | undefined;
  SeedLabel: SeedLabel | undefined;
}
