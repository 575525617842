
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SeedLabel from "../../types/SeedLabels/SeedLabel";
import SeedLabelApiService from "@/services/SeedLabelApiService";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import { toastMessage } from "@/types/Interfaces/ToastMessage";

@Component({
  components: {
    DeleteIcon
  }
})
export default class UpdateLabel extends Vue {
  @Prop() value!: SeedLabel;
  @Prop() nameRules!: [];
  @Prop({ default: false }) show!: boolean;
  formValid = false;
  apiService = new SeedLabelApiService();

  @Emit()
  toggle() {
    return !this.show;
  }

  update() {
    (this.$refs.UpdateLabelForm as HTMLFormElement).validate();
    if (!this.formValid) return;
    this.apiService
      .UpdateLabel(this.value)
      .then(response => {
        toastMessage({
          message: response.Success
            ? (this.$t("LabelUpdated") as string)
            : (this.$t("FailedLabelUpdate") as string),
          type: response.Success ? "success" : "error"
        });
        this.toggle();
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedLabelUpdate") as string,
          type: "error"
        });
      });
  }

  @Emit()
  onDelete() {
    return;
  }
}
