import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import OrganizationType from "@/types/Organization.ts";
import OrganizationApiService from "@/services/OrganizationApiService";

@Module({ namespaced: true, name: "organization" })
export default class Organization extends VuexModule {
  orgApiService = new OrganizationApiService();
  searchTerm = "";
  organizations = new Array<OrganizationType>();
  isSearching = false;
  selectedOrganization: OrganizationType = new OrganizationType();

  /**
   * @Getters
   */
  get IsSearching() {
    return this.isSearching;
  }

  get Organizations() {
    return this.organizations;
  }

  get SearchTerm() {
    return this.searchTerm;
  }

  get SelectedOrganization() {
    return this.selectedOrganization;
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_ORGANIZATIONS(value: []) {
    this.organizations = value;
  }

  @Mutation
  SET_IS_SEARCHING(value: boolean) {
    this.isSearching = value;
  }

  @Mutation
  SET_SEARCH_TERM(value: string) {
    this.searchTerm = value;
  }

  @Mutation
  SET_SELECTED_ORGANIZATION(value: OrganizationType) {
    this.selectedOrganization = value;
  }

  /**
   * @Actions
   */
  @Action
  FetchOrganizations() {
    this.orgApiService
      .GetOrganizations(this.searchTerm)
      .then(resp => {
        this.context.commit("SET_ORGANIZATIONS", resp.Value);
        this.context.commit("SET_IS_SEARCHING", false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  SetSearchTerm(value: string) {
    this.context.commit("SET_SEARCH_TERM", value);
    this.context.commit("SET_IS_SEARCHING", true);
  }

  @Action
  UpdateIsSearching(value: boolean) {
    this.context.commit("SET_IS_SEARCHING", value);
  }

  @Action
  SetSelectedOrganization(value: OrganizationType) {
    this.context.commit("SET_SELECTED_ORGANIZATION", value);
  }

  @Action
  FetchOrganizationById(id: string) {
    this.orgApiService
      .GetOrganizationById(id)
      .then(resp => {
        this.context.commit("SET_SELECTED_ORGANIZATION", resp.Value[0]);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  SetupOrganizationLookup(id: string) {
    this.context.commit("SET_IS_SEARCHING", true);
    this.orgApiService
      .GetOrganizationById(id)
      .then(resp => {
        this.context.commit("SET_SELECTED_ORGANIZATION", resp.Value[0]);
        this.context.commit("SET_SEARCH_TERM", resp.Value[0].Name);
        this.context.dispatch("FetchOrganizations");
      })
      .catch(error => {
        console.error(error);
      });
  }
}
