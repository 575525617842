
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SeedYearApiService from "@/services/SeedYear/SeedYearApiService";
import SeedYear from "@/types/SeedYear/SeedYear";
import DatePicker from "@/components/DatePicker.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import seedYearModule from "@/modules/seedYears";
import { getModule } from "vuex-module-decorators";
import SeedYearInfo from "@/components/SeedYearInfo.vue";
import AddSubmissionGroup from "@/components/modals/AddSubmissionGroup.vue";
import SeedYearSubmissionGroup from "@/types/SeedYear/SeedYearSubmissionGroup";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("AddSeason") as string
    };
  },
  components: {
    SeedYearInfo,
    DatePicker,
    ConfirmButton,
    AddSubmissionGroup,
    DeleteIcon
  }
})
export default class SeedYearCreate extends Vue {
  seedYearState = getModule(seedYearModule, this.$store);
  seedYear: SeedYear = new SeedYear();
  apiService: SeedYearApiService = new SeedYearApiService();
  isFormValid = false;

  get AlreadySelectedGroups() {
    return this.seedYear.SeedYearSubmissionGroups.map(
      item => item.SubmissionGroupId
    );
  }

  getLabels(group: SubmissionGroup | undefined): string {
    if (group) {
      return group.SubmissionGroupLabels.map(
        (x: SubmissionGroupLabel) => x.Label.SeedPackLabel
      ).join();
    } else {
      return "";
    }
  }

  save() {
    (this.$refs.SeedYearCreateForm as HTMLFormElement).validate();
    if (!this.isFormValid) return;
    this.seedYear.SeedYearSubmissionGroups.forEach(
      item => (item.SubmissionGroup = undefined)
    );
    this.apiService
      .CreateSeedYear(this.seedYear)
      .then(resp => {
        if (resp.Success) {
          this.seedYearState.FetchSeedYears();
          this.$router.push("/Admin/SeedYear");
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  truncate(value: string) {
    const cutOffPoint = 25;
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  addSeedYearSubmissionGroup(value: SeedYearSubmissionGroup) {
    this.seedYear.SeedYearSubmissionGroups.push(value);
  }

  removeSeedYearSubmissionGroup(sysg: SeedYearSubmissionGroup) {
    const removalIndex = this.seedYear.SeedYearSubmissionGroups.indexOf(sysg);
    this.seedYear.SeedYearSubmissionGroups.splice(removalIndex, 1);
  }

  cancel() {
    this.$router.push("/Admin/SeedYear");
  }
}
