
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SubmissionStatistics from "@/types/SeedLabelSubmission/SubmissionStatistics";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLookup from "@/components/SubmissionGroupLookup.vue";
import Doughnut from "@/components/charts/Doughnut.vue";
import SecurityService from "@/services/Auth/SecurityService";
import SeedLabelSubmissionHeader from "@/types/SeedLabelSubmission/SeedLabelSubmissionHeader";
import SeedLabelSubmissionItem from "@/types/SeedLabelSubmission/SeedLabelSubmissionItem";
import Guid from "@/types/Guid";

@Component({
  components: { SubmissionGroupLookup, Doughnut },
  metaInfo() {
    return {
      title: this.$root.$t("SubmissionResults") as string
    };
  }
})
export default class SubmissionResults extends Vue {
  securityService: SecurityService = new SecurityService();
  apiService: SubmissionApiService = new SubmissionApiService();
  submissionGroup: SubmissionGroup = new SubmissionGroup();
  submissionStats: SubmissionStatistics = new SubmissionStatistics();

  submissionHeaderId = "";
  submissionGroupId = "";
  submissionHeader: SeedLabelSubmissionHeader = new SeedLabelSubmissionHeader();

  get nationalResultLink() {
    return this.$i18n.locale == "fr"
      ? this.submissionGroup.NationalResultsUrl_Fr
        ? this.submissionGroup.NationalResultsUrl_Fr
        : "https://parlonssciences.ca/tomatosphere/resultats/"
      : this.submissionGroup.NationalResultsUrl_En
      ? this.submissionGroup.NationalResultsUrl_En
      : "https://letstalkscience.ca/tomatosphere/results/";
  }

  headers: object[] = [
    {
      text: this.$root.$t("Label"),
      align: "left",
      value: "SeedLabel.SeedPackLabel"
    },
    {
      text: this.$root.$t("Treatment"),
      align: "left",
      value: "SeedLabel.Treatment"
    },
    {
      text: this.$root.$t("Planted"),
      align: "left",
      value: "Planted"
    },
    {
      text: this.$root.$t("Germinated"),
      align: "left",
      value: "Germinated"
    },
    {
      text: this.$root.$t("PercentageGerminated"),
      align: "left",
      value: "PercentageGerminated"
    }
  ];

  mounted() {
    if (this.$route.query.id) {
      this.submissionGroupId = this.$route.query.id as string;
      this.submissionHeaderId = this.$route.query.hid as string;
      this.getData();
    }
  }

  getData() {
    if (this.submissionHeaderId) {
      this.apiService
        .All([
          this.apiService.GetSubmissionByHeaderId(this.submissionHeaderId),
          this.apiService.GetSubmissionGroupById(this.submissionGroupId)
        ])
        .then(result => {
          const header = result[0];

          const items: SeedLabelSubmissionItem[] =
            header.Value.SeedLabelSubmissionItems;
          const groupedItems: Map<string, SeedLabelSubmissionItem> = new Map<
            string,
            SeedLabelSubmissionItem
          >();
          for (let i = 0; i < items.length; i++) {
            const item: SeedLabelSubmissionItem = items[i];
            const grouped: SeedLabelSubmissionItem =
              groupedItems.get(item.SeedLabel.SeedPackLabel) ||
              new SeedLabelSubmissionItem();
            if (grouped.SeedLabelId == Guid.Empty) {
              groupedItems.set(item.SeedLabel.SeedPackLabel, item);
            } else {
              if (grouped.Planted) grouped.Planted += item.Planted || 0;
              if (grouped.Germinated)
                grouped.Germinated += item.Germinated || 0;
            }
          }

          console.log(groupedItems.values());

          //header.Value.SeedLabelSubmissionItems = header.Value.SeedLabelSubmissionItems.sort(
          header.Value.SeedLabelSubmissionItems = Array.from(
            groupedItems.values()
          ).sort((a: SeedLabelSubmissionItem, b: SeedLabelSubmissionItem) => {
            if (a.SeedLabel.SeedPackLabel > b.SeedLabel.SeedPackLabel) return 1;
            else if (a.SeedLabel.SeedPackLabel < b.SeedLabel.SeedPackLabel)
              return -1;
            else return 0;
          });

          this.submissionHeader = header.Value;

          const seedYear = result[1];
          this.submissionGroup = seedYear.Value;
        });
    }
  }

  onSubmissionGroupInput() {
    this.getData();
  }

  resetStatData() {
    this.submissionStats = new SubmissionStatistics();
  }
}
