import BaseEntity from "../BaseEntity";
import Guid from "../Guid";
import SeedLabel from "../SeedLabels/SeedLabel";

export default class SeedLabelSubmissionItem extends BaseEntity {
  SeedLabelSubmissionHeaderId: string = Guid.Empty;
  SeedLabelId: string = Guid.Empty;
  Planted: number | undefined;
  Germinated: number | undefined;
  SeedLabel: SeedLabel = new SeedLabel();
}
