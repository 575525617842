
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SubmissionStatistics from "@/types/SeedLabelSubmission/SubmissionStatistics";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLookup from "@/components/SubmissionGroupLookup.vue";
import Doughnut from "@/components/charts/Doughnut.vue";
import SeedLabelStatistics from "@/types/SeedYear/SeedLabelStatistics";

@Component({
  components: { SubmissionGroupLookup, Doughnut },
  metaInfo() {
    return {
      title: this.$root.$t("NationalResults") as string
    };
  }
})
export default class NationalResults extends Vue {
  apiService: SubmissionApiService = new SubmissionApiService();
  submissionGroup: SubmissionGroup = new SubmissionGroup();
  submissionStats: SubmissionStatistics = new SubmissionStatistics();

  headers: object[] = [
    {
      text: this.$root.$t("Label"),
      align: "left",
      value: "SeedLabel.SeedPackLabel"
    },
    {
      text: this.$root.$t("Treatment"),
      align: "left",
      value: "SeedLabel.Treatment"
    },
    {
      text: this.$root.$t("Planted"),
      align: "left",
      value: "Planted"
    },
    {
      text: this.$root.$t("Germinated"),
      align: "left",
      value: "Germinated"
    },
    {
      text: this.$root.$t("PercentageGerminated"),
      align: "left",
      value: "PercentageGerminated"
    }
  ];

  mounted() {
    document.getElementById("navigation-bar")!.remove();
    if (this.$route.query.id) {
      this.submissionGroup.Id = this.$route.query.id as string;
      this.getData();
    }
  }

  gotoNationalResults() {
    window.open(
      this.$i18n.locale == "fr"
        ? "https://parlonssciences.ca/tomatosphere/resultats"
        : "https://letstalkscience.ca/tomatosphere/results"
    );
  }

  getData() {
    this.apiService
      .GetSubmissionStatisticsBySubmissionGroupId(this.submissionGroup.Id)
      .then(resp => {
        resp.Value.LabelStatistics = resp.Value.LabelStatistics.sort(
          (a: SeedLabelStatistics, b: SeedLabelStatistics) => {
            if (a.SeedLabel.SeedPackLabel > b.SeedLabel.SeedPackLabel) return 1;
            else if (a.SeedLabel.SeedPackLabel < b.SeedLabel.SeedPackLabel)
              return -1;
            else return 0;
          }
        );

        this.submissionStats = resp.Value;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onSubmissionGroupInput() {
    this.getData();
  }

  resetStatData() {
    this.submissionStats = new SubmissionStatistics();
  }
}
