
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class IntField extends Vue {
  @Prop(Number) value: number | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) rules: Array<Function> | undefined;

  strValue: string = this.value ? this.value.toString() : "";

  @Watch("value")
  onValueChanged() {
    this.strValue = this.value ? this.value.toString() : "";
  }

  IntegerValidation() {
    return (
      this.strValue === "" ||
      parseFloat(this.strValue) % 1 === 0 ||
      `number must be a valid integer`
    );
  }

  get AllRules(): Function[] {
    const r: Function[] = this.rules || [];
    r.push(this.IntegerValidation);
    return r;
  }

  // eslint-disable-next-line
  onInput(val: any) {
    //   this is because strings like "1." evaluate to 1
    //   and are considered valid, but we want to return 1
    const ret = isNaN(parseFloat(this.strValue))
      ? ""
      : parseFloat(this.strValue).toString();
    this.$emit("input", parseInt(ret));
  }
}
