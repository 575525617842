import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("CreateGroup"))+" ")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("CreateGroup"))+" ")]),_c(VForm,{ref:"AddSubmissionGroupModalForm",on:{"submit":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}},model:{value:(_vm.IsFormValid),callback:function ($$v) {_vm.IsFormValid=$$v},expression:"IsFormValid"}},[_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('Name'),"rules":_vm.nameRules},model:{value:(_vm.value.Name),callback:function ($$v) {_vm.$set(_vm.value, "Name", $$v)},expression:"value.Name"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }