import BaseEntity from "../BaseEntity";
import Guid from "../Guid";

export default class SeedYearLocale extends BaseEntity {
  constructor(locale: string) {
    super();
    this.Locale = locale;
  }

  SeedYearId = Guid.Empty;
  Locale: string;
  PlantingPeriod = "";
  ShippingWindow = "";
  Description = "";
}
