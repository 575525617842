
import { Component, Prop, Vue } from "vue-property-decorator";
import Chart from "chart.js/auto";
import { ChartData } from "chart.js";

@Component
export default class Doughnut extends Vue {
  @Prop({ default: [] }) readonly data!: Array<number>;

  readonly options: object | undefined = {
    cutout: "80%",
    plugins: {
      legend: {
        position: "bottom"
      }
    }
  };
  readonly id: string = Math.random().toString();

  mounted() {
    // The chart isn't showing planted and germinated it's showing the % germinated
    const dataForChart = [this.data[1], this.data[0] - this.data[1]];

    this.createChart({
      labels: [this.$root.$t("Germinated"), this.$root.$t("Ungerminated")],
      datasets: [
        {
          label: "# seeds",
          data: dataForChart,
          backgroundColor: ["rgba(236, 38,39, 1)", "rgba(0, 0, 0, 1)"],
          borderColor: ["rgba(236, 38,39, 1)", "rgba(0, 0, 0, 1)"],
          borderWidth: 1
        }
      ]
    });
  }

  createChart(chartData: ChartData) {
    new Chart(document.getElementById(this.id) as HTMLCanvasElement, {
      type: "doughnut",
      data: chartData,
      options: this.options
    });
  }
}
