import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.show,"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.toggle},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VCard,[_c(VToolbar,{attrs:{"color":"primaryred","dark":""}},[_c('div',{staticClass:"toolbar d-flex justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(`${_vm.$t("Update")} ${_vm.$t("Label")}`))]),_c(VBtn,{staticClass:"delete grey--text text--darken-2",attrs:{"color":"grey lighten-3"},on:{"click":_vm.onDelete}},[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" "),_c(VIcon,{attrs:{"medium":""}},[_vm._v(" mdi-delete ")])],1)],1)]),_c(VForm,{ref:"UpdateLabelForm",on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('Name'),"rules":_vm.nameRules},model:{value:(_vm.value.SeedPackLabel),callback:function ($$v) {_vm.$set(_vm.value, "SeedPackLabel", $$v)},expression:"value.SeedPackLabel"}}),_c(VCheckbox,{attrs:{"label":_vm.$t('Treatment')},model:{value:(_vm.value.Treatment),callback:function ($$v) {_vm.$set(_vm.value, "Treatment", $$v)},expression:"value.Treatment"}}),_c('div',{staticClass:"black--text font-weight-bold"},[_vm._v(" *** "+_vm._s(_vm.$t("UpdateLabelWarning"))+" ")])],1),_c(VCardActions,{staticClass:"justify-space-between"},[_c(VBtn,{on:{"click":_vm.toggle}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")]),_c(VBtn,{staticClass:"primaryred",attrs:{"dark":"","text":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Update")))])],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }