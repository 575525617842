
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditGradeModal from "@/components/modals/EditGradeModal.vue";
import TSEnumerationLocale from "@/types/EnumerationLocale";

@Component({
  components: {
    DeleteIcon,
    EditGradeModal
  }
})
export default class GradeItem extends Vue {
  @Prop({ default: () => new SeedOrderClassGrade() })
  grade!: SeedOrderClassGrade;
  @Prop({ default: () => [] }) grades!: Array<TSEnumerationLocale>;
  @Prop({ default: () => [] }) selectedGrades!: Array<SeedOrderClassGrade>;

  showEditModal = false;

  get gradeText() {
    const [currentGrade] = this.grades.filter(
      item => item.Id === this.grade.GradeId
    );
    return currentGrade.Text;
  }

  get NumberOfStudents() {
    return this.grade.NumStudents;
  }

  toggle() {
    this.showEditModal = !this.showEditModal;
  }

  update(value: SeedOrderClassGrade) {
    this.grade.GradeId = value.GradeId;
    this.grade.NumStudents = value.NumStudents;
  }

  @Emit()
  removeGrade() {
    return this.grade;
  }
}
