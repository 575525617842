
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ConfirmButton extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) icon: string | undefined;
  @Prop(String) tooltip: string | undefined;

  confirm = false;

  onConfirmed() {
    this.confirm = false;
    this.$emit("confirmed");
  }
}
