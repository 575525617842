
import { Component, Prop, Vue } from "vue-property-decorator";
import Guid from "../../types/Guid";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionGroupLookup from "@/components/SubmissionGroupLookup.vue";
import SeedYearSubmissionGroup from "@/types/SeedYear/SeedYearSubmissionGroup";

@Component({
  components: { SubmissionGroupLookup }
})
export default class AddSubmissionGroup extends Vue {
  @Prop({ default: () => Guid.Empty }) seedYearId!: string;
  @Prop({ default: () => [] }) alreadySelectedGroupIds!: Array<string>;
  show = false;
  isFormValid = false;
  seedYearSubmissionGroup: SeedYearSubmissionGroup = new SeedYearSubmissionGroup();
  group: SubmissionGroup = new SubmissionGroup();
  errorMessages: Array<string> = [];

  toggle() {
    this.show = !this.show;
  }

  get lookupRules() {
    return [() => this.isGroupSelected && this.isGroupAlreadySelected];
  }

  get isGroupSelected() {
    return this.seedYearSubmissionGroup.SubmissionGroupId !== Guid.Empty;
  }

  get isGroupAlreadySelected() {
    return (
      this.alreadySelectedGroupIds.find(
        item => item === this.seedYearSubmissionGroup!.SubmissionGroupId
      ) === undefined
    );
  }

  Validate() {
    (this.$refs.AddSubmissionGroupForm as HTMLFormElement).validate();
    this.errorMessages = [];
    if (!this.isGroupSelected)
      this.errorMessages.push(this.$t("SelectGroup") as string);

    if (!this.isGroupAlreadySelected)
      this.errorMessages.push(this.$t("GroupAlreadySelected") as string);
  }

  addGroup(group: SubmissionGroup) {
    if (!group) {
      this.reset();
      this.Validate();
      return;
    }
    this.group = group;
    this.seedYearSubmissionGroup.SeedYearId = this.seedYearId;
    this.seedYearSubmissionGroup.SubmissionGroupId = this.group.Id;
    this.seedYearSubmissionGroup.SubmissionGroup = this.group;
    this.Validate();
  }

  save() {
    this.Validate();
    if (!this.isFormValid) return;

    this.toggle();
    this.$emit("save", this.seedYearSubmissionGroup);
    this.reset();
  }

  reset() {
    this.seedYearSubmissionGroup = new SeedYearSubmissionGroup();
    this.group = new SubmissionGroup();
  }
}
