
import { Component, Vue } from "vue-property-decorator";
import SubmissionGroupNavigation from "@/views/admin/SubmissionGroups/SubmissionGroupNavigation.vue";
import Results from "@/views/admin/SubmissionGroups/Search/Results.vue";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import SearchSubmissionGroupPagination from "@/types/SeedLabelSubmission/SearchSubmissionGroupPagination";
import Paging from "@/components/search/Paging.vue";
import { debounce } from "lodash";

@Component({
  components: { Results, SubmissionGroupNavigation, Paging }
})
export default class SubmissionGroups extends Vue {
  apiService = new SubmissionApiService();
  isSearching = false;
  submissionGroupPagination = new SearchSubmissionGroupPagination();
  searchSubmissionGroups = debounce((searchTerm: string) => {
    this.search(searchTerm);
  }, 500);

  get submissionGroups() {
    return this.submissionGroupPagination.Value;
  }

  get currentPage() {
    return this.submissionGroupPagination.CurrentPage;
  }

  get lastPage() {
    return this.submissionGroupPagination.LastPage;
  }

  get hasSubmissionGroups() {
    return this.submissionGroups.length > 0;
  }

  mounted() {
    this.search("");
  }

  search(searchTerm: string) {
    this.isSearching = true;
    this.submissionGroupPagination.SearchTerm = searchTerm;
    this.apiService
      .SearchSubmissionGroup(this.submissionGroupPagination)
      .then(response => {
        this.submissionGroupPagination = response.Value;
        this.isSearching = false;
      })
      .catch(error => {
        console.error(error);
        this.isSearching = false;
        toastMessage({
          message: this.$t("FailedToSearch") as string,
          type: "error"
        });
      });
  }

  onSort(data: { sortProperty: string; isAscendingOrder: boolean }) {
    this.submissionGroupPagination.AscendingOrder = data.isAscendingOrder;
    this.submissionGroupPagination.SortProp = data.sortProperty;
    this.search(this.submissionGroupPagination.SearchTerm);
  }

  previousPage() {
    this.submissionGroupPagination.CurrentPage -= 1;
    this.search(this.submissionGroupPagination.SearchTerm);
  }

  nextPage() {
    this.submissionGroupPagination.CurrentPage += 1;
    this.search(this.submissionGroupPagination.SearchTerm);
  }

  setSearching() {
    this.isSearching = true;
  }
}
