import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VForm,{ref:"form"},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("MailingAddress")))]),_c(VTextField,{attrs:{"label":_vm.$t('Recipient'),"rules":[
        () => _vm.shippingInfo.Addresse.length > 0 || `${_vm.$t('Rule_Recipient')}`
      ]},model:{value:(_vm.shippingInfo.Addresse),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "Addresse", $$v)},expression:"shippingInfo.Addresse"}}),_c(VTextField,{attrs:{"label":_vm.$t('CompanySchoolName'),"persistent-hint":"","hint":_vm.$t('CompanySchoolNameHint')},model:{value:(_vm.shippingInfo.CompanyName),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "CompanyName", $$v)},expression:"shippingInfo.CompanyName"}}),_c(VSwitch,{staticClass:"pb-0",attrs:{"label":_vm.$t('CantFindAddress')},on:{"click":_vm.auxAddressClick},model:{value:(_vm.auxAddress),callback:function ($$v) {_vm.auxAddress=$$v},expression:"auxAddress"}}),(!_vm.auxAddress)?_c('CanadaPostAddressLookup',{ref:"cpAddressLookup",attrs:{"label":_vm.$t('MailingAddress'),"address":`${_vm.shippingInfo.Line1} - ${_vm.shippingInfo.City}, ${_vm.shippingInfo.RegionCode}, ${_vm.shippingInfo.PostalCode}`,"rules":[() => _vm.ValidMailingAddress || `${_vm.$t('Rule_MailingAddress')}`]},on:{"click:clear":_vm.onCPAddressClearClick,"input":_vm.onAddressSelected}}):_c('div',[_c('div',{staticClass:"ml-4",staticStyle:{"max-width":"500px"}},[_c(VTextField,{attrs:{"label":_vm.$t('Address'),"hint":_vm.$t('AddressHint'),"persistent-hint":"","rules":[
            () =>
              _vm.shippingInfo.Line1.length > 0 || `${_vm.$t('Rule_MailingAddress')}`
          ]},model:{value:(_vm.shippingInfo.Line1),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "Line1", $$v)},expression:"shippingInfo.Line1"}}),_c(VTextField,{attrs:{"label":_vm.$t('City'),"rules":[
            () => _vm.shippingInfo.City.length > 0 || `${_vm.$t('Rule_City')}`
          ]},model:{value:(_vm.shippingInfo.City),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "City", $$v)},expression:"shippingInfo.City"}}),_c('ProvinceSelect',{attrs:{"label":_vm.$t('Province'),"rules":[
            () =>
              _vm.shippingInfo.RegionName.length > 0 || `${_vm.$t('Rule_Province')}`
          ]},model:{value:(_vm.shippingInfo.RegionName),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "RegionName", $$v)},expression:"shippingInfo.RegionName"}}),_c('PostalCodeField',{staticStyle:{"max-width":"175px"},attrs:{"label":_vm.$t('PostalCode'),"rules":[
            () =>
              _vm.shippingInfo.PostalCode.length > 0 || `${_vm.$t('Rule_PostalCode')}`
          ]},model:{value:(_vm.shippingInfo.PostalCode),callback:function ($$v) {_vm.$set(_vm.shippingInfo, "PostalCode", $$v)},expression:"shippingInfo.PostalCode"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }