
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SearchSubmissionGroupPagination from "@/types/SeedLabelSubmission/SearchSubmissionGroupPagination";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";

@Component
export default class SubmissionGroupLookup extends Vue {
  @ModelSync("selectedValue", "input", { default: () => new SubmissionGroup() })
  value!: SubmissionGroup;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: () => [] }) rules!: [];
  @Prop({ default: () => "" }) errorMessages!: [] | "";
  @Prop({ default: false }) hideNoData!: boolean;
  @Prop({ default: "" }) ariaAutocomplete!: string;
  @Prop({ default: false }) clearable!: boolean;

  submissionGroupPagination = new SearchSubmissionGroupPagination();
  apiService = new SubmissionApiService();
  isLoading = false;

  get items() {
    return this.submissionGroupPagination.Value;
  }

  itemText(value: SubmissionGroup) {
    return value.Name;
  }

  itemValue(value: SubmissionGroup) {
    return value;
  }

  mounted() {
    this.getGroups();
  }

  getGroups() {
    this.isLoading = true;
    this.apiService
      .SearchSubmissionGroup(this.submissionGroupPagination)
      .then(response => {
        response.Value.Value = response.Value.Value.filter(x => {
          return x.IsActive;
        });
        response.Value.Value.sort((a: SubmissionGroup, b: SubmissionGroup) => {
          if (a.DateCreated > b.DateCreated) return -1;
          else if (a.DateCreated > b.DateCreated) return 1;
          else return 0;
        });
        this.submissionGroupPagination = response.Value;
        this.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        this.isLoading = false;
      });
  }
}
