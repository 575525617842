import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import EnumerationApiService from "@/services/EnumerationApiService";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import TSEnumerationLocale from "@/types/EnumerationLocale";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import SeedOrderClassApiService from "@/services/SeedOrderClassApiService";
import Guid from "@/types/Guid";
import ClassGradeApiService from "@/services/ClassGradeApiService";
import { i18n } from "@/i18n";

@Module({ namespaced: true, name: "classroomPicker" })
export default class ClassroomPicker extends VuexModule {
  grades: TSEnumerationLocale[] = [];
  classes: SeedOrderClass[] = [];
  enumApiService: EnumerationApiService = new EnumerationApiService();
  orderClassApiService: SeedOrderClassApiService = new SeedOrderClassApiService();
  classGradeApiService: ClassGradeApiService = new ClassGradeApiService();

  /**
   * @Getters
   */
  get Grades() {
    return this.grades;
  }

  get Classes() {
    return this.classes.filter(c => !c.Deleted);
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_GRADES(value: TSEnumerationLocale[]) {
    this.grades = value;
  }

  @Mutation
  SET_CLASSES(value: SeedOrderClass[]) {
    this.classes = value;
  }

  @Mutation
  ADD_GRADE(payload: {
    value: SeedOrderClassGrade;
    orderClass: SeedOrderClass;
  }) {
    payload.orderClass.SeedOrderClassGrades.push(payload.value);
  }

  @Mutation
  REMOVE_GRADE(payload: {
    grade: SeedOrderClassGrade;
    orderClass: SeedOrderClass;
  }) {
    payload.orderClass.SeedOrderClassGrades = payload.orderClass.SeedOrderClassGrades.filter(
      item => item.Id !== payload.grade.Id
    );
  }

  @Mutation
  ADD_SEED_ORDER_CLASS(value: SeedOrderClass) {
    this.classes.push(value);
  }

  @Mutation
  REMOVE_ORDER_CLASS(id: Guid) {
    this.classes = this.classes.filter(orderClass => orderClass.Id !== id);
  }

  /**
   * @Actions
   */
  @Action
  FetchGrades() {
    this.enumApiService
      .GetGrades()
      .then(response => {
        this.context.commit("SET_GRADES", response.Value);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  SetClasses(classes: SeedOrderClass[]) {
    this.context.commit("SET_CLASSES", classes);
  }

  @Action
  AddGrade(value: SeedOrderClassGrade) {
    this.classGradeApiService
      .CreateClassGrade(value)
      .then(response => {
        const orderClass = this.classes.find(
          item => item.Id === response.Value.SeedOrderClassId
        );
        if (orderClass) {
          if (!orderClass.SeedOrderClassGrades)
            orderClass.SeedOrderClassGrades = [];
          this.context.commit("ADD_GRADE", {
            orderClass,
            value: response.Value
          });
        }
        toastMessage({
          message: response.Success
            ? (i18n.t("CreatedGrade") as string)
            : (i18n.t("FailedGradeCreate") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  RemoveGrade(grade: SeedOrderClassGrade) {
    this.classGradeApiService
      .DeleteClassGrade(grade.Id)
      .then(response => {
        const orderClass = this.classes.find(
          item => item.Id === grade.SeedOrderClassId
        );
        if (response.Success && orderClass)
          this.context.commit("REMOVE_GRADE", {
            orderClass,
            grade
          });
        toastMessage({
          message: response.Success
            ? (i18n.t("DeletedGrade") as string)
            : (i18n.t("FailedGradeDelete") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  AddSeedOrderClass(orderId: Guid) {
    this.orderClassApiService
      .CreateOrderClass(orderId)
      .then(response => {
        this.context.commit("ADD_SEED_ORDER_CLASS", response.Value);
        toastMessage({
          message: response.Success
            ? (i18n.t("CreatedOrderClass") as string)
            : (i18n.t("FailedOrderClassCreate") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  RemoveSeedOrderClass(id: Guid) {
    this.orderClassApiService
      .DeleteOrderClass(id)
      .then(response => {
        this.context.commit("REMOVE_ORDER_CLASS", id);
        toastMessage({
          message: response.Success
            ? (i18n.t("DeletedOrderClass") as string)
            : (i18n.t("FailedOrderClassDelete") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  UpdateGrade(grade: SeedOrderClassGrade) {
    this.classGradeApiService
      .UpdateClassGrade(grade)
      .then(response => {
        toastMessage({
          message: response.Success
            ? (i18n.t("UpdatedGrade") as string)
            : (i18n.t("FailedGradeUpdate") as string),
          type: response.Success ? "success" : "error"
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
}
