import { RouteConfig } from "vue-router";
import SeedLabels from "@/views/admin/SeedLabels/SeedLabels.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Admin/SeedLabels",
    name: "SeedLabels",
    component: SeedLabels
  }
];

export default routes;
