export class CanadaPostAddressItem {
  Id = "";
  Text = "";
  Description = "";
  Next = "";
  LastId = "";

  constructor(
    id: string,
    text: string,
    description: string,
    next: string,
    lastId: string
  ) {
    this.Id = id;
    this.Text = text;
    this.Description = description;
    this.Next = next;
    this.LastId = lastId;
  }
}
