
import { Component, Vue, Prop, ModelSync } from "vue-property-decorator";

@Component
export default class NumberInput extends Vue {
  @ModelSync("value", "change", { default: 0 })
  numValue!: number;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: () => [] }) rules!: Array<Function>;
  @Prop({ default: Number.MIN_SAFE_INTEGER }) min!: number;
  @Prop({ default: Number.MAX_SAFE_INTEGER }) max!: number;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: 0 }) resetValue!: number;
  @Prop({ default: undefined }) hint: string | undefined;

  checkValue(val: number) {
    if (val < this.min || val > this.max) {
      this.numValue = this.resetValue;
    }
  }
}
