import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"inline"}},[_c(VTooltip,{attrs:{"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({on:{"click":function($event){_vm.confirm = true}}},on),[_vm._v(_vm._s(_vm.icon))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]),_c(VDialog,{attrs:{"width":"unset"},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}},[_c('div',{staticStyle:{"background":"white","padding":"10px"}},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._t("default")],2),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onConfirmed}},[_vm._v(_vm._s(_vm.$t("Ok")))]),_c(VBtn,{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":function($event){_vm.confirm = false}}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }