
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class Paging extends Vue {
  @Prop({ default: "" }) btnClass!: string;
  @Prop({ default: false }) disable!: boolean;
  @Prop({ default: 1 }) currentPage!: number;
  @Prop({ default: 1 }) lastPage!: number;

  @Emit()
  previousPage() {
    return;
  }

  @Emit()
  nextPage() {
    return;
  }
}
