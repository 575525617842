import UseCaseResponse from "@/types/UseCaseResponse";
import Organization from "@/types/Organization.ts";
import { BaseApiService, axiosApi } from "./BaseApiService";

export default class OrganizationApiService extends BaseApiService {
  async GetOrganizations(
    filter: string
  ): Promise<UseCaseResponse<Organization[]>> {
    return axiosApi
      .get(`/api/Organization?query=${filter}`)
      .then(resp => resp.data);
  }

  GetOrganizationById(id: string): Promise<UseCaseResponse<Organization[]>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/api/Organization/${id}`)
        .then(response => {
          resolve(response.data as UseCaseResponse<Organization[]>);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
