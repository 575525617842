import { render, staticRenderFns } from "./SubmissionGroupRadioGroup.vue?vue&type=template&id=506aaaa3&scoped=true&"
import script from "./SubmissionGroupRadioGroup.vue?vue&type=script&lang=ts&"
export * from "./SubmissionGroupRadioGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506aaaa3",
  null
  
)

export default component.exports