import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import AdminApiService from "../services/AdminApiService";
import SecurityService from "../services/Auth/SecurityService";
//import { SecurityService } from "@/services/BaseApiService";

@Module({ namespaced: true, name: "auth" })
export default class Auth extends VuexModule {
  isAdmin = false;
  apiService: AdminApiService = new AdminApiService();
  securityService: SecurityService = new SecurityService();
  isLoggedIn = false;

  @Mutation
  SET_IS_ADMIN(value: boolean) {
    this.isAdmin = value;
  }

  @Mutation
  SET_IS_LOGGED_IN(value: boolean) {
    this.isLoggedIn = value;
  }

  @Action
  SetIsLoggedIn(value: boolean) {
    this.context.commit("SET_IS_LOGGED_IN", value);
  }

  @Action
  Authenticate() {
    this.apiService
    .IsAdmin()
    .then(data => {
      this.context.commit("SET_IS_ADMIN", data.Value);
    })
    .catch(error => {
      console.error(error);
    });    
    this.securityService.userManager
      .getUser()
      .then(user => {
        this.context.commit(
          "SET_IS_LOGGED_IN",
          user!.access_token !== undefined
        );
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action
  async Logout() {
    try {
      await this.securityService.logout();
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async Login() {
    try {
      if (!this.isLoggedIn) {
        await this.securityService.login();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
