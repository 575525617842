
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import SearchSeedLabelPagination from "@/types/SeedLabels/SearchSeedLabelPagination";
import SeedLabelApiService from "@/services/SeedLabelApiService";

@Component({})
export default class LabelLookup extends Vue {
  @ModelSync("selectedValue", "input", { default: () => new SeedLabel() })
  value!: SeedLabel;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: () => [] }) rules!: [];
  @Prop({ default: () => "" }) errorMessages!: [] | "";
  @Prop({ default: false }) hideNoData!: boolean;
  @Prop({ default: "" }) ariaAutocomplete!: string;
  labelPagination = new SearchSeedLabelPagination();
  labelApiService = new SeedLabelApiService();
  isLoading = false;

  get items() {
    return this.labelPagination.Value;
  }

  itemText(value: SeedLabel) {
    return `${value.SeedPackLabel} - (${this.$t("Treatment")} ${
      value.Treatment ? this.$t("Yes") : this.$t("No")
    })`;
  }

  itemValue(value: SeedLabel) {
    return value;
  }

  search() {
    this.isLoading = true;
    this.labelApiService
      .SearchLabels(this.labelPagination)
      .then(response => {
        this.labelPagination = response.Value;
        this.isLoading = false;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
