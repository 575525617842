import { axiosApi, BaseApiService } from "@/services/BaseApiService";
import SearchSeedLabelPagination from "@/types/SeedLabels/SearchSeedLabelPagination";
import UseCaseResponse from "@/types/UseCaseResponse";
import SeedLabel from "@/types/SeedLabels/SeedLabel";

export default class SeedLabelApiService extends BaseApiService {
  SearchLabels(
    payload: SearchSeedLabelPagination
  ): Promise<UseCaseResponse<SearchSeedLabelPagination>> {
    return new Promise<UseCaseResponse<SearchSeedLabelPagination>>(
      (resolve, reject) => {
        axiosApi
          .get("/api/tomatosphere/SeedLabelSearch", {
            params: {
              SearchTerm: payload.SearchTerm,
              CurrentPage: payload.CurrentPage,
              LastPage: payload.LastPage,
              Total: payload.Total,
              PageSize: payload.PageSize,
              AscendingOrder: payload.AscendingOrder,
              SortProp: payload.SortProp
            }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }
    );
  }

  CreateLabel(label: SeedLabel): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .post("/api/tomatosphere/SeedLabel", label)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  UpdateLabel(label: SeedLabel): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .put(`/api/tomatosphere/SeedLabel/${label.Id}`, label)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  DeleteLabel(id: string): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SeedLabel/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
