import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(`${_vm.$t("Add")} ${_vm.$t("Grade")}`)+" ")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(`${_vm.$t("Add")} ${_vm.$t("Grade")}`)+" ")]),_c(VForm,{ref:"addGradeModalForm",model:{value:(_vm.IsFormValid),callback:function ($$v) {_vm.IsFormValid=$$v},expression:"IsFormValid"}},[_c(VCardText,[_c(VSelect,{attrs:{"label":_vm.$t('Grade'),"items":_vm.availableGrades,"item-text":_vm.itemText,"item-value":_vm.itemValue,"rules":_vm.gradeSelectRules(),"required":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('number-input',{attrs:{"label":_vm.$t('NumStudents'),"rules":_vm.numberOfStudentInputRules(),"min":_vm.minNumberOfStudents,"max":_vm.maxNumberOfStudents,"hint":_vm.numberInputHint,"required":""},model:{value:(_vm.numberOfStudents),callback:function ($$v) {_vm.numberOfStudents=$$v},expression:"numberOfStudents"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","dark":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }