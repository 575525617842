import SeedOrder from "@/types/SeedOrder/SeedOrder";
import Pagination from "@/types/Interfaces/Pagination";

export default class SearchSeedOrderPagination
  implements Pagination<SeedOrder> {
  SearchTerm: string;
  SortProp: string;
  AscendingOrder: boolean;
  CurrentPage: number;
  LastPage: number;
  Total: number;
  PageSize: number;
  SeedYearIds: string;
  Value: Array<SeedOrder>;

  constructor() {
    this.CurrentPage = 1;
    this.LastPage = 1;
    this.Total = 0;
    this.PageSize = 20;
    this.Value = [];
    this.SearchTerm = "";
    this.SortProp = "";
    this.AscendingOrder = false;
    this.SeedYearIds = "";
  }
}
