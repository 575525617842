
import { Component, Vue } from "vue-property-decorator";
import SearchInput from "@/components/search/SearchInput.vue";
import SearchSeedLabelPagination from "@/types/SeedLabels/SearchSeedLabelPagination";
import { debounce } from "lodash";
import Results from "@/views/admin/SeedLabels/Search/Results.vue";
import CreateLabel from "@/views/admin/SeedLabels/CreateLabel.vue";
import SeedLabelApiService from "@/services/SeedLabelApiService";

@Component({
  components: { Results, SearchInput, CreateLabel }
})
export default class SeedLabels extends Vue {
  searchDto: SearchSeedLabelPagination = new SearchSeedLabelPagination();
  isSearching = false;
  seedLabelApi: SeedLabelApiService = new SeedLabelApiService();
  searchLabels = debounce((searchTerm: string) => {
    this.search(searchTerm);
  }, 500);

  searching() {
    this.isSearching = true;
  }

  search(searchTerm: string) {
    this.searchDto.SearchTerm = searchTerm;
    this.isSearching = true;
    this.seedLabelApi
      .SearchLabels(this.searchDto)
      .then(response => {
        this.searchDto = response.Value;
        this.isSearching = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  prevPage() {
    this.searchDto.CurrentPage -= 1;
    this.isSearching = true;
    this.search(this.searchDto.SearchTerm);
  }

  nextPage() {
    this.searchDto.CurrentPage += 1;
    this.isSearching = true;
    this.search(this.searchDto.SearchTerm);
  }

  labelCreated() {
    this.search(this.searchDto.SearchTerm);
  }

  labelDeleted() {
    this.search(this.searchDto.SearchTerm);
  }

  mounted() {
    this.search("");
  }
}
