
import { Vue, Component, Prop } from "vue-property-decorator";
import GradeItem from "@/components/classroom-picker/GradeItem.vue";
import AddGradeModal from "@/components/modals/AddGradeModal.vue";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import { getModule } from "vuex-module-decorators";
import classroomPickerModule from "@/modules/classroomPicker";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";

@Component({
  components: {
    GradeItem,
    AddGradeModal
  }
})
export default class ClassItem extends Vue {
  @Prop() seedOrderClass!: SeedOrderClass;
  @Prop({ default: () => 0 }) seedOrderClassIndex!: number;
  @Prop({ default: false }) editable!: boolean;
  classroomPickerState = getModule(classroomPickerModule, this.$store);

  get classGrades() {
    return this.seedOrderClass.SeedOrderClassGrades
      ? this.seedOrderClass.SeedOrderClassGrades.filter(
          classGrade => !classGrade.Deleted
        )
      : [];
  }

  get AvailableGrades() {
    return this.classroomPickerState.Grades.filter(
      g =>
        !this.seedOrderClass.SeedOrderClassGrades ||
        !this.seedOrderClass.SeedOrderClassGrades.some(
          usedGrade => usedGrade.GradeId === g.Id
        )
    );
  }

  deleteClass() {
    this.classroomPickerState.RemoveSeedOrderClass(this.seedOrderClass.Id);
  }

  addGrade(value: SeedOrderClassGrade) {
    this.classroomPickerState.AddGrade(JSON.parse(JSON.stringify(value)));
  }
}
