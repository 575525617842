import { BaseApiService, axiosApi } from "@/services/BaseApiService";

export default class AdminApiService extends BaseApiService {
  // eslint-disable-next-line
  async Report(reportName: string): Promise<any> {
    return axiosApi({
      url: `/api/tomatosphere/Report/${reportName}`,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${reportName}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}
