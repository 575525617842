
import { Component, Vue, Prop } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import UpdateLabel from "@/components/modals/UpdateLabel.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import Swal, { SweetAlertOptions } from "sweetalert2";
import SeedLabelApiService from "@/services/SeedLabelApiService";
import { toastMessage } from "@/types/Interfaces/ToastMessage";

@Component({
  components: { DeleteIcon, UpdateLabel }
})
export default class ResultLineItem extends Vue {
  @Prop({ default: () => new SeedLabel() }) label!: SeedLabel;
  apiService = new SeedLabelApiService();
  DEFAULT_VALUE = "N/A";
  show = false;

  get textLimit() {
    return 25;
  }

  get Name() {
    return this.isEmpty(this.label.SeedPackLabel, this.DEFAULT_VALUE);
  }

  get IsTreated() {
    return this.label.Treatment;
  }

  get NameRules() {
    return [() => this.label.SeedPackLabel.length > 0];
  }

  isEmpty(value: string | undefined, defaultVal = ""): string {
    return !value || value.length === 0 ? defaultVal : value;
  }

  truncate(value: string, cutOffPoint: number) {
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  toggle() {
    this.show = !this.show;
  }

  onDelete() {
    this.toggle();
    Swal.fire({
      title: this.$t("Are you Sure?"),
      text: this.$t("DeleteLabelWarning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.$t("Delete") as string,
      confirmButtonColor: "#c62828",
      cancelButtonText: this.$t("Cancel") as string
    } as SweetAlertOptions).then(result => {
      if (result.isConfirmed) {
        this.apiService
          .DeleteLabel(this.label.Id)
          .then(response => {
            toastMessage({
              message: this.$t(
                response.Success ? "LabelDeleted" : "FailedLabelDelete"
              ) as string,
              type: response.Success ? "success" : "error"
            });
            this.$emit("delete");
          })
          .catch(error => {
            console.error(error);
            toastMessage({
              message: this.$t("FailedLabelDelete") as string,
              type: "error"
            });
          });
      }
    });
  }
}
