import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primaryred","dark":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(`${_vm.$t("Create")} ${_vm.$t("Label")}`))])]}},{key:"default",fn:function(){return [_c(VCard,[_c(VToolbar,{attrs:{"color":"primaryred","dark":""}},[_vm._v("Create Label")]),_c(VForm,{ref:"CreateLabelForm",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('Name'),"rules":_vm.NameRules},model:{value:(_vm.label.SeedPackLabel),callback:function ($$v) {_vm.$set(_vm.label, "SeedPackLabel", $$v)},expression:"label.SeedPackLabel"}}),_c(VCheckbox,{attrs:{"label":_vm.$t('Treatment')},model:{value:(_vm.label.Treatment),callback:function ($$v) {_vm.$set(_vm.label, "Treatment", $$v)},expression:"label.Treatment"}})],1),_c(VCardActions,{staticClass:"justify-space-between"},[_c(VBtn,{on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c(VBtn,{staticClass:"primaryred",attrs:{"dark":"","text":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})
}
var staticRenderFns = []

export { render, staticRenderFns }