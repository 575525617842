import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import OrderRoutes from "@/router/OrderRoutes";
import SeedYearRoutes from "@/router/SeedYearRoutes";
import SubmitResultRoutes from "@/router/SubmitResultRoutes";
import SeedLabelRoutes from "@/router/SeedLabelRoutes";
import DashboardRoutes from "@/router/DashboardRoutes";
import SubmissionGroupRoutes from "@/router/SubmissionGroupRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...OrderRoutes,
  ...SeedYearRoutes,
  ...SubmitResultRoutes,
  ...SeedLabelRoutes,
  ...DashboardRoutes,
  ...SubmissionGroupRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
