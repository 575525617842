
import { Component, Vue, Prop, ModelSync } from "vue-property-decorator";
import SeedYear from "@/types/SeedYear/SeedYear";
import SeedYearLocale from "@/types/SeedYear/SeedYearLocale";
import moment from "moment";
import Guid from "@/types/Guid";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import SeedYearSelect from "@/components/selects/SeedYearSelect.vue";
import SeedYearListItem from "@/views/admin/SeedYear/SeedYearListItem.vue";
@Component({
  components: { SeedYearListItem, SeedYearSelect }
})
export default class SelectSeasonForm extends Vue {
  @Prop({ default: () => [] }) seedYears!: Array<SeedYear>;
  @Prop({ default: () => [] }) orders!: Array<SeedOrder>;
  @ModelSync("order", "change") orderValue!: SeedOrder;

  get valid(): boolean {
    return (this.$refs.SeedYearSelectForm as HTMLFormElement).validate();
  }

  get headers() {
    return [
      {
        text: this.$root.$t("PlantingPeriod"),
        align: "center",
        class: "select-year-form-header",
        value: "PlantingPeriod"
      },
      {
        text: this.$root.$t("ShippingWindow"),
        align: "center",
        class: "select-year-form-header",
        value: "ShippingWindow"
      },
      {
        text: this.$root.$t("StartDate"),
        align: "center",
        class: "select-year-form-header",
        value: "StartDate"
      },
      {
        text: this.$root.$t("EndDate"),
        align: "center",
        class: "select-year-form-header",
        value: "EndDate"
      },
      {
        text: this.$root.$t("SeedsOrdered"),
        align: "center",
        class: "select-year-form-header",
        cellClass: "d-flex justify-center",
        value: "HasSubjectOrdered"
      }
    ];
  }

  get seedYearRules(): Array<Function> {
    return [
      () =>
        (this.orderValue.SeedYearId.length > 0 &&
          this.orderValue.SeedYearId !== Guid.Empty &&
          this.orderValue.SeedYearId === this.orderValue.SeedYear.Id) ||
        `${this.$t("Rule_Season")}`
    ];
  }

  get activeSeedYears(): SeedYear[] {
    const seedYearsOrdered: string[] = this.orders.map(x => {
      return x.SeedYearId;
    });
    return this.seedYears.filter(year => {
      return year.IsActive && !seedYearsOrdered.includes(year.Id);
    });
  }

  get errorMessages(): Array<string> {
    const messages: Array<string> = [];

    if (!this.activeSeedYears.length)
      messages.push(this.$t("UnableToOrderSeeds") as string);

    return messages;
  }

  tableRowStyling() {
    return "non-selectable-row";
  }

  seedYearLocale(year: SeedYear): SeedYearLocale {
    return year.getCurrentLocale(this.$i18n.locale);
  }

  seedYearDate(date: string) {
    return moment(date)
      .locale(this.$i18n.locale)
      .local()
      .format(this.$t("DateFormat") as string);
  }

  onSeedYearInput(year: SeedYear) {
    this.orderValue.SeedYear = year;
    this.orderValue.SeedYearId = year.Id;
  }

  orderedIcon(seedYearId: string): string {
    return this.orders.some(year => year.SeedYearId === seedYearId)
      ? "mdi-check"
      : "";
  }
}
