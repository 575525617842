import BaseEntity from "@/types/BaseEntity";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import Guid from "@/types/Guid";

export default class SubmissionGroupLabel extends BaseEntity {
  LabelId: string = Guid.Empty;
  Label: SeedLabel;
  SubmissionGroupId: string = Guid.Empty;
  SubmissionGroup: SubmissionGroup | undefined;

  constructor() {
    super();
    this.Label = new SeedLabel();
  }
}
