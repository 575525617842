
import { Component, Vue, ModelSync, Emit, Prop } from "vue-property-decorator";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import TSEnumerationLocale from "@/types/EnumerationLocale";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import AddGradeModal from "@/components/modals/AddGradeModal.vue";
import GradeItem from "@/components/classroom-info/GradeItem.vue";

@Component({
  components: {
    GradeItem,
    AddGradeModal
  }
})
export default class ClassroomItem extends Vue {
  @ModelSync("value", "change", { default: () => new SeedOrderClass() })
  classroom!: SeedOrderClass;
  @Prop({ default: 0 }) classroomNum!: number;
  @Prop({ default: () => [] }) grades!: Array<TSEnumerationLocale>;

  get AvailableGrades() {
    return this.grades.filter(
      g =>
        !this.classroom.SeedOrderClassGrades.some(item => item.GradeId === g.Id)
    );
  }

  addGrade(value: SeedOrderClassGrade) {
    this.classroom.SeedOrderClassGrades.push(value);
  }

  removeGrade(classGrade: SeedOrderClassGrade) {
    const removalIndex = this.classroom.SeedOrderClassGrades.indexOf(
      classGrade
    );
    this.classroom.SeedOrderClassGrades.splice(removalIndex, 1);
  }

  @Emit()
  remove() {
    return this.classroom;
  }
}
