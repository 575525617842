
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DeleteIcon extends Vue {
  @Prop({ default: "" }) color!: string;
  @Prop({ default: 0 }) elevation!: number;

  @Emit()
  onDelete() {
    return;
  }
}
