import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VTextField,{attrs:{"label":_vm.$t('Description'),"rules":[
      () =>
        _vm.seedYearLocale('en').Description.length > 0 || `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('en').Description),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('en'), "Description", $$v)},expression:"seedYearLocale('en').Description"}}),_c(VTextField,{attrs:{"label":`${_vm.$t('Description')} (${_vm.$t('French')})`,"rules":[
      () =>
        _vm.seedYearLocale('fr').Description.length > 0 || `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('fr').Description),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('fr'), "Description", $$v)},expression:"seedYearLocale('fr').Description"}}),_c(VTextField,{attrs:{"label":_vm.$t('PlantingPeriod'),"rules":[
      () =>
        _vm.seedYearLocale('en').PlantingPeriod.length > 0 ||
        `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('en').PlantingPeriod),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('en'), "PlantingPeriod", $$v)},expression:"seedYearLocale('en').PlantingPeriod"}}),_c(VTextField,{attrs:{"label":`${_vm.$t('PlantingPeriod')} (${_vm.$t('French')})`,"rules":[
      () =>
        _vm.seedYearLocale('fr').PlantingPeriod.length > 0 ||
        `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('fr').PlantingPeriod),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('fr'), "PlantingPeriod", $$v)},expression:"seedYearLocale('fr').PlantingPeriod"}}),_c(VTextField,{attrs:{"label":_vm.$t('ShippingWindow'),"rules":[
      () =>
        _vm.seedYearLocale('en').ShippingWindow.length > 0 ||
        `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('en').ShippingWindow),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('en'), "ShippingWindow", $$v)},expression:"seedYearLocale('en').ShippingWindow"}}),_c(VTextField,{attrs:{"label":`${_vm.$t('ShippingWindow')} (${_vm.$t('French')})`,"rules":[
      () =>
        _vm.seedYearLocale('fr').ShippingWindow.length > 0 ||
        `${_vm.$t('IsRequired')}`
    ]},model:{value:(_vm.seedYearLocale('fr').ShippingWindow),callback:function ($$v) {_vm.$set(_vm.seedYearLocale('fr'), "ShippingWindow", $$v)},expression:"seedYearLocale('fr').ShippingWindow"}}),_c('DatePicker',{attrs:{"label":_vm.$t('StartDate'),"rules":[() => _vm.seedYear.StartDate.length > 0 || `${_vm.$t('IsRequired')}`]},model:{value:(_vm.seedYear.StartDate),callback:function ($$v) {_vm.$set(_vm.seedYear, "StartDate", $$v)},expression:"seedYear.StartDate"}}),_c('DatePicker',{attrs:{"label":_vm.$t('EndDate'),"rules":[() => _vm.seedYear.EndDate.length > 0 || `${_vm.$t('IsRequired')}`]},model:{value:(_vm.seedYear.EndDate),callback:function ($$v) {_vm.$set(_vm.seedYear, "EndDate", $$v)},expression:"seedYear.EndDate"}}),_c(VCheckbox,{attrs:{"label":_vm.$t('Active')},model:{value:(_vm.seedYear.IsActive),callback:function ($$v) {_vm.$set(_vm.seedYear, "IsActive", $$v)},expression:"seedYear.IsActive"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }