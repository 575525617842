import UseCaseResponse from "@/types/UseCaseResponse";
import { BaseApiService, axiosApi } from "./BaseApiService";
import { i18n } from "@/i18n";
import TSEnumerationLocale from "@/types/EnumerationLocale";

export default class EnumerationApiService extends BaseApiService {
  async GetGrades(): Promise<UseCaseResponse<TSEnumerationLocale[]>> {
    return axiosApi
      .get(
        `/api/Organization/Enumeration/LTSAgeGroups?locale=${i18n.locale}-CA`
      )
      .then(resp => resp.data);
  }
}
