import BaseEntity from "@/types/BaseEntity";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";

export default class SubmissionGroup extends BaseEntity {
  Name = "";
  NationalResultsUrl_En = "";
  NationalResultsUrl_Fr = "";
  DateCreated = "";
  IsActive = false;
  SubmissionGroupLabels: Array<SubmissionGroupLabel> = [];
}
