import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center"},[_c(VCard,{staticClass:"create-card my-4",attrs:{"elevation":"2"}},[_c(VCardTitle,[_c('div',{staticClass:"display-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Create")))])]),_c(VCardText,[_c(VForm,{ref:"SeedYearCreateForm",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('seed-year-info',{attrs:{"year":_vm.seedYear}}),_c('div',[_c('div',{staticClass:"d-flex align-center mt-4"},[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t("SeedGroups"))+" ")]),_c('add-submission-group',{attrs:{"seed-year-id":_vm.seedYear.Id,"already-selected-label-ids":_vm.AlreadySelectedGroups},on:{"save":_vm.addSeedYearSubmissionGroup}})],1),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" Name ")]),_c('th',[_vm._v(" Labels ")]),_c('th')])]),_c('tbody',_vm._l((_vm.seedYear.SeedYearSubmissionGroups),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(item.SubmissionGroup !== undefined ? item.SubmissionGroup.Name : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getLabels(item.SubmissionGroup))+" ")]),_c('td',[_c('delete-icon',{staticClass:"delete",attrs:{"color":"grey darken-3"},on:{"on-delete":_vm.removeSeedYearSubmissionGroup}})],1)])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c(VBtn,{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c(VBtn,{staticClass:"primaryred",attrs:{"dark":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }