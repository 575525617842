import { axiosApi, BaseApiService } from "@/services/BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import Guid from "@/types/Guid";

export default class SeedOrderClassApiService extends BaseApiService {
  CreateOrderClass(orderId: Guid): Promise<UseCaseResponse<SeedOrderClass>> {
    return new Promise<UseCaseResponse<SeedOrderClass>>((resolve, reject) => {
      axiosApi
        .post(`/api/tomatosphere/SeedOrderClass/${orderId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  DeleteOrderClass(id: Guid): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SeedOrderClass/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
