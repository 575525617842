
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import SeedYear from "@/types/SeedYear/SeedYear";

@Component
export default class SeedYearSelect extends Vue {
  @Prop() seedYears!: Array<SeedYear>;
  @Prop() rules!: Array<Function>;
  @Prop() value!: SeedYear;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) readonly!: boolean;

  @Emit()
  onInput(value: SeedYear) {
    return value;
  }

  itemText(item: SeedYear) {
    return item.getCurrentLocale(this.$i18n.locale).PlantingPeriod;
  }

  valueComparer(leftSide: SeedYear, rightSide: SeedYear) {
    return leftSide && rightSide ? leftSide.Id === rightSide.Id : false;
  }

  itemValue(item: SeedYear) {
    return item;
  }
}
