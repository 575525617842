
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import TSEnumerationLocale from "@/types/EnumerationLocale";
import NumberInput from "@/components/fields/NumberInput.vue";

@Component({
  components: { NumberInput }
})
export default class AddGradeModal extends Vue {
  @Prop({ default: () => "" }) orderClassId!: string;
  @Prop({ default: () => [] }) availableGrades!: Array<TSEnumerationLocale>;
  @Prop({ default: 1 }) minNumberOfStudents!: number;
  @Prop({ default: 32 }) maxNumberOfStudents!: number;
  show = false;
  IsFormValid = false;
  numberOfStudents = 0;
  grade: TSEnumerationLocale = new TSEnumerationLocale();
  value: SeedOrderClassGrade = new SeedOrderClassGrade();

  get numberInputHint() {
    return (this.$t("Hint_NumberInput") as string)
      .replace("NUM1", this.minNumberOfStudents.toString())
      .replace("NUM2", this.maxNumberOfStudents.toString());
  }

  @Watch("grade")
  onGradeChange(newVal: TSEnumerationLocale) {
    this.value.GradeId = newVal.Id;
  }

  @Watch("numberOfStudents")
  onNumberOfStudentsChange(newVal: number) {
    this.value.NumStudents = newVal;
  }

  gradeSelectRules() {
    return [() => this.grade.Id.length > 0 && this.grade.Text.length > 0];
  }

  numberOfStudentInputRules() {
    return [
      () =>
        this.numberOfStudents >= this.minNumberOfStudents &&
        this.numberOfStudents <= this.maxNumberOfStudents
    ];
  }

  toggle() {
    this.show = !this.show;
  }

  itemText(value: TSEnumerationLocale) {
    return value.Text;
  }

  itemValue(value: TSEnumerationLocale) {
    return value;
  }

  add() {
    (this.$refs.addGradeModalForm as HTMLFormElement).validate();
    if (this.IsFormValid) {
      this.$emit("add", this.value);
      this.toggle();
      this.reset();
    }
  }

  cancel() {
    this.toggle();
  }

  mounted() {
    this.value.SeedOrderClassId = this.orderClassId;
    this.value.NumStudents = this.numberOfStudents;
  }

  reset() {
    this.value = new SeedOrderClassGrade();
    this.value.SeedOrderClassId = this.orderClassId;
    this.numberOfStudents = 1;
    this.grade = new TSEnumerationLocale();
  }
}
