
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class SearchInput extends Vue {
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "" }) textFieldClass!: string;

  @Emit()
  keydown(e: KeyboardEvent) {
    return e;
  }

  @Emit()
  input(value: string) {
    return value;
  }
}
