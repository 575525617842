
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import YearLabel from "@/types/YearLabel";
import YearLabelListItem from "@/components/year-label-list-view/YearLabelListItem.vue";

@Component({
  components: { YearLabelListItem }
})
export default class YearLabelList extends Vue {
  @Prop({ default: () => [] }) items!: Array<YearLabel>;

  @Emit()
  deleteItem(yearLabel: YearLabel) {
    return yearLabel;
  }
}
