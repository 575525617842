
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SortableHeader from "@/components/table/SortableHeader.vue";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import ResultLineItem from "@/views/admin/SubmissionGroups/Search/ResultLineItem.vue";

@Component({
  components: {
    ResultLineItem,
    SortableHeader
  }
})
export default class Results extends Vue {
  @Prop({ default: [] }) submissionGroups!: Array<SubmissionGroup>;
  @Prop({ default: false }) isSearching!: boolean;

  @Emit()
  onSort(data: { sortProperty: string; isAscendingOrder: boolean }) {
    return data;
  }
}
