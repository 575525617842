import Vue from "vue";
import Vuex from "vuex";
import order from "@/modules/order";
import auth from "@/modules/auth";
import organization from "@/modules/organization";
import seedYears from "@/modules/seedYears";
import classroomPicker from "@/modules/classroomPicker";
import canadaPostAddressLookup from "@/modules/canadaPostAddressLookup";
import submission from "@/modules/submission";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    order,
    auth,
    organization,
    seedYears,
    classroomPicker,
    canadaPostAddressLookup,
    submission
  }
});
export default store;
