import UseCaseResponse from "@/types/UseCaseResponse";
import { BaseApiService, axiosApi } from "./BaseApiService";

export default class AdminApiService extends BaseApiService {
  async IsAdmin(): Promise<UseCaseResponse<boolean>> {
    return new Promise((resolve, reject) => {
      axiosApi
        .get("/api/tomatosphere/Admin")
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }
}
