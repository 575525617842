
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import ClassSubmission from "@/types/SeedLabelSubmission/ClassSubmission";
import SubmissionItem from "@/components/classroom-submission/SubmissionItem.vue";

@Component({
  components: {
    SubmissionItem
  }
})
export default class ClassSubmissions extends Vue {
  @Prop({ default: 0 }) number!: number;
  @Prop({ default: () => new ClassSubmission() }) value!: ClassSubmission;
  @Prop({ default: () => [] }) labels!: Array<SeedLabel>;

  @Emit()
  removeClassSubmission() {
    return this.value;
  }
}
