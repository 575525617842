
import Vue from "vue";
import { Component } from "vue-property-decorator";
import orderModule from "@/modules/order";
import seedYearsModule from "@/modules/seedYears";
import { getModule } from "vuex-module-decorators";
import SchoolLookup from "@/components/SchoolLookup.vue";
import AuxOrganizationInfo from "@/components/AuxOrganizationInfo.vue";
import NumberSelect from "@/components/selects/NumberSelect.vue";
import OrganizationLookup from "@/components/OrganizationLookup.vue";
import Organization from "@/types/Organization";
import SeedYearSelect from "@/components/selects/SeedYearSelect.vue";
import SeedYear from "@/types/SeedYear/SeedYear";
import ClassroomPicker from "@/components/classroom-picker/ClassroomPicker.vue";
import CanadaPostAddressLookup from "@/components/CanadaPostAddressLookup.vue";
import ProvinceSelect from "@/components/selects/ProvinceSelect.vue";
import PostalCodeField from "@/components/PostalCodeField.vue";
import DataObject from "@/types/DataObject";
import router from "@/router";
import Swal, { SweetAlertOptions } from "sweetalert2";
import AuxOrganization from "@/types/AuxOrganization";
import orgModule from "@/modules/organization";

@Component({
  components: {
    OrganizationLookup,
    SchoolLookup,
    AuxOrganizationInfo,
    NumberSelect,
    SeedYearSelect,
    ClassroomPicker,
    CanadaPostAddressLookup,
    ProvinceSelect,
    PostalCodeField
  }
})
export default class EditOrder extends Vue {
  orderState = getModule(orderModule, this.$store);
  seedYearsState = getModule(seedYearsModule, this.$store);
  orgState = getModule(orgModule, this.$store);
  useAuxOrganization = this.orderState.SelectedOrder!.OrganizationId;
  useAuxAddress = false;
  isFormValid = false;

  get EditOrderTitle() {
    return `${this.$t("Edit")} ${this.$t("Order")}`;
  }

  get OrganizationRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.OrganizationId &&
          this.orderState.SelectedOrder.OrganizationId !== "") ||
        `${this.$t("IsRequired")}`
    ];
  }

  get EducatorSelectRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.NumEducators > 0) ||
        `${this.$t("IsRequired")}`
    ];
  }

  get ClassroomSelectRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.NumClasses) > 0 ||
        `${this.$t("IsRequired")}`
    ];
  }

  get SeedYearSelectRules() {
    return [
      () =>
        this.orderState.SelectedOrder !== undefined ||
        `${this.$t("IsRequired")}`
    ];
  }

  get CanadaPostAddressLookupRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.Line1.length >
            0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.Street.length >
            0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.City.length > 0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.PostalCode
            .length > 0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.RegionName
            .length > 0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.RegionCode
            .length > 0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.Country.length >
            0 &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.BuildingNumber
            .length > 0) ||
        `${this.$t("IsRequired")}`
    ];
  }

  get AuxAddressRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.Line1.length >
            0) ||
        this.$t("IsRequired")
    ];
  }

  get AuxCityRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.City.length >
            0) ||
        this.$t("IsRequired")
    ];
  }

  get AuxProvinceRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.RegionName
            .length > 0) ||
        this.$t("IsRequired")
    ];
  }

  get AuxPostalCodeRules() {
    return [
      () =>
        (this.orderState.SelectedOrder !== undefined &&
          this.orderState.SelectedOrder.SeedOrderShippingInfo.PostalCode
            .length > 0) ||
        this.$t("IsRequired")
    ];
  }

  get CanadaPostAddress() {
    return this.orderState.SelectedOrder !== undefined
      ? `${this.orderState.SelectedOrder.SeedOrderShippingInfo.Line1} - ${this.orderState.SelectedOrder.SeedOrderShippingInfo.City}, ${this.orderState.SelectedOrder.SeedOrderShippingInfo.RegionCode}, ${this.orderState.SelectedOrder.SeedOrderShippingInfo.PostalCode}`
      : "";
  }

  update() {
    (this.$refs.EditOrderForm as HTMLFormElement).validate();
    if (!this.isFormValid) return;
    this.orderState.UpdateOrder();
  }

  cancel() {
    this.orderState.ResetSelectedOrder();
    this.NavigateToOrderSearchPage();
  }

  deleteOrder() {
    Swal.fire({
      title: this.$t("AreYouSure"),
      text: this.$t("ThisActionWillDeleteTheOrder"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.$t("Delete") as string,
      confirmButtonColor: "#c62828",
      cancelButtonText: this.$t("Cancel") as string
    } as SweetAlertOptions).then(result => {
      if (result.isConfirmed && this.orderState.SelectedOrder) {
        this.orderState.DeleteOrder(this.orderState.SelectedOrder.Id);
        this.NavigateToOrderSearchPage();
      }
    });
  }

  onAuxOrganizationSwitch() {
    if (!this.orderState.SelectedOrder!.AuxOrganization)
      this.orderState.SetSelectedOrderAuxOrganization({
        StreetAddress: "",
        Region: "",
        Name: "",
        City: "",
        PostalCode: ""
      });

    if (this.useAuxOrganization) {
      this.orderState.SetSelectedOrderOrganization("");
    } else {
      this.orderState.SetSelectedOrderAuxOrganization(new AuxOrganization());
    }
  }

  onAuxAddressSwitch() {
    this.clearShippingInfo();
  }

  onOrganizationLookupInput(value: Organization) {
    this.orderState.SetSelectedOrderOrganization(value.Id);
  }

  onSeedYearSelectInput(value: SeedYear) {
    this.orderState.SetSelectedOrderSeedYear(value);
  }

  onNumEducatorSelect(value: number) {
    this.orderState.SetSelectedOrderEducatorNumber(value);
  }

  onCanadaPostAddressLookup(canadaPostAddr: DataObject) {
    this.orderState.SetSelectedOrderShippingInfo(canadaPostAddr);
  }

  clearShippingInfo() {
    this.orderState.SetSelectedOrderShippingInfo({
      Line1: "",
      Line2: "",
      Street: "",
      City: "",
      PostalCode: "",
      ProvinceName: "",
      ProvinceCode: "",
      CountryName: "",
      SubBuilding: "",
      BuildingNumber: ""
    });
  }

  mounted() {
    if (!this.orderState.SelectedOrder) this.NavigateToOrderSearchPage();
  }

  NavigateToOrderSearchPage() {
    router.push({ name: "Orders" });
  }
}
