import BaseEntity from "../BaseEntity";
import SeedYearLocale from "./SeedYearLocale";
import SeedYearSubmissionGroup from "@/types/SeedYear/SeedYearSubmissionGroup";
import Guid from "@/types/Guid";

export default class SeedYear extends BaseEntity {
  IsActive = false;
  StartDate = "";
  EndDate = "";
  SeedYearSubmissionGroups: SeedYearSubmissionGroup[] = [];
  SeedYearLocales: SeedYearLocale[] = [];

  constructor(
    isActive = false,
    startDate = "",
    endDate = "",
    seedYearSubmissionGroups: SeedYearSubmissionGroup[] = [],
    yearLocales: SeedYearLocale[] = [],
    id = Guid.Empty,
    deleted = false
  ) {
    super();
    this.Id = id;
    this.Deleted = deleted;
    this.IsActive = isActive;
    this.StartDate = startDate;
    this.EndDate = endDate;
    this.SeedYearSubmissionGroups = seedYearSubmissionGroups;

    if (!yearLocales.length) this.setupYearLocale();
    else this.SeedYearLocales = yearLocales;
  }

  copy(year: SeedYear) {
    this.Id = year.Id;
    this.Deleted = year.Deleted;
    this.IsActive = year.IsActive;
    this.StartDate = year.StartDate;
    this.EndDate = year.EndDate;
    this.SeedYearSubmissionGroups = year.SeedYearSubmissionGroups;
    this.SeedYearLocales = year.SeedYearLocales;
  }

  setupYearLocale() {
    const en: SeedYearLocale = new SeedYearLocale("en");
    en.Locale = "en";
    this.SeedYearLocales.push(en);
    const fr: SeedYearLocale = new SeedYearLocale("fr");

    this.SeedYearLocales.push(fr);
  }

  getCurrentLocale(locale: string): SeedYearLocale {
    const currentLocale = this.SeedYearLocales.find(
      yearLocale => yearLocale.Locale === locale
    );

    return currentLocale ? currentLocale : new SeedYearLocale(locale);
  }
}
