
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("ThankYouForOrdering") as string
    };
  }
})
export default class ThankYou extends Vue {
  @Prop(Object) value!: SeedOrder;

  get SeedOrder(): SeedOrder {
    return this.value || new SeedOrder();
  }

  get SeedYearLocale(): string {
    const [seedYearLocale] = this.value.SeedYear.SeedYearLocales.filter(
      item => item.Locale === this.$i18n.locale
    );
    return seedYearLocale.Description;
  }

  get totalStudents(): string {
    let students = 0;
    this.value.SeedOrderClasses.flatMap(
      valueClass => valueClass.SeedOrderClassGrades
    ).forEach(classGrade => {
      students += parseInt(classGrade.NumStudents.toString());
    });
    return `${students}`;
  }

  /*get TotalStudents(): number {
    let ret = 0;
    this.SeedOrder.SeedOrderClasses.forEach((soClass: SeedOrderClass) => {
      soClass.SeedOrderClassGrades.forEach((grade: SeedOrderClassGrade) => {
        ret += Number(grade.NumStudents);
        console.log(grade.NumStudents);
        console.log(ret);
      });
    });

    return ret;
  }*/

  @Emit()
  placeAnotherOrder() {
    return;
  }
}
