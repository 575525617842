import { axiosApi, BaseApiService } from "@/services/BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import Guid from "@/types/Guid";

export default class ClassGradeApiService extends BaseApiService {
  CreateClassGrade(
    classGrade: SeedOrderClassGrade
  ): Promise<UseCaseResponse<SeedOrderClassGrade>> {
    return new Promise<UseCaseResponse<SeedOrderClassGrade>>(
      (resolve, reject) => {
        axiosApi
          .post(`/api/tomatosphere/SeedOrderClassGrade`, classGrade)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }
    );
  }

  DeleteClassGrade(id: Guid): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .delete(`/api/tomatosphere/SeedOrderClassGrade/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  UpdateClassGrade(
    classGrade: SeedOrderClassGrade
  ): Promise<UseCaseResponse<boolean>> {
    return new Promise<UseCaseResponse<boolean>>((resolve, reject) => {
      axiosApi
        .put(`/api/tomatosphere/SeedOrderClassGrade`, classGrade)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
