
import { Component, Vue } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import SeedLabelApiService from "@/services/SeedLabelApiService";
import { toastMessage } from "@/types/Interfaces/ToastMessage";

@Component({})
export default class CreateLabel extends Vue {
  show = false;
  formValid = false;
  label: SeedLabel = new SeedLabel();
  labelApiService = new SeedLabelApiService();

  get NameRules() {
    return [(value: string) => value.length > 0];
  }

  create() {
    (this.$refs.CreateLabelForm as HTMLFormElement).validate();
    if (!this.formValid) return;

    this.labelApiService
      .CreateLabel(this.label)
      .then(response => {
        if (response.Success) {
          toastMessage({
            message: this.$t("LabelCreated") as string,
            type: "success"
          });
          this.$emit("created");
          this.reset();
        }
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedLabelCreate") as string,
          type: "error"
        });
      });
    this.toggle();
  }

  reset() {
    this.label = new SeedLabel();
  }

  toggle() {
    this.show = !this.show;
  }
}
