
import { Component, Prop, Vue } from "vue-property-decorator";
import SubmissionGroupLabel from "@/types/SeedLabelSubmission/SubmissionGroupLabel";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import SubmissionModule from "@/modules/submission";
import { getModule } from "vuex-module-decorators";
@Component({
  components: { DeleteIcon }
})
export default class SubmissionGroupLabelItem extends Vue {
  @Prop() submissionGroupLabel!: SubmissionGroupLabel;
  submissionState = getModule(SubmissionModule, this.$store);

  get label() {
    return this.submissionGroupLabel.Label;
  }

  removeLabel() {
    this.submissionState.removeSubmissionGroupLabel(
      this.submissionGroupLabel.Id
    );
  }
}
