
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Navigation from "./TsNav.vue";
import authModule from "@/modules/auth";
import { getModule } from "vuex-module-decorators";
import seedYearsModule from "@/modules/seedYears";

@Component({
  components: {
    Navigation
  }
})
export default class TomatosphereApp extends Vue {
  authState = getModule(authModule, this.$store);
  seedYearsState = getModule(seedYearsModule, this.$store);

  mounted() {
    console.log("1.8.0");
    // hacky, but needed unless we update the way auth works (which we should do)
    if (this.$router.currentRoute.name != "NationalResults") {
      this.authState.Authenticate();
      this.authState.Login()
      this.seedYearsState.FetchSeedYears();
    }
  }
}
