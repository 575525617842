
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import SeedYearLocale from "@/types/SeedYear/SeedYearLocale";
import { defaultTo } from "lodash";

@Component({
  components: {
    TermsAndConditions
  }
})
export default class OrderConfirmation extends Vue {
  @Prop(Object) value!: SeedOrder;
  @Prop(Boolean) termsAccepted: boolean | undefined;
  termsOk = false;
  termsAndConditions = false;

  get seedYear(): string {
    return `${this.$t("SeedYear")}${this.$t("Colon")}${
      this.seedYearLocale.PlantingPeriod
    }`;
  }

  get totalClasses(): string {
    return `${this.$t("NumberOfClassrooms")}${this.$t("Colon")}${
      this.value.SeedOrderClasses.length
    }`;
  }

  get totalStudents(): string {
    let students = 0;
    this.value.SeedOrderClasses.flatMap(
      valueClass => valueClass.SeedOrderClassGrades
    ).forEach(classGrade => {
      students += parseInt(classGrade.NumStudents.toString());
    });
    return `${this.$t("TotalStudents")}${this.$t("Colon")}${students}`;
  }

  get companyName(): string {
    const name = defaultTo(this.organizationName, "");
    return `${this.$t("OrderedSeedsFor")}${this.$t("Colon")}${name}`;
  }

  get organizationName(): string | undefined {
    let organizationName = "";
    if (this.value.Organization)
      organizationName = this.value.Organization.Name;

    return this.value.AuxOrganization && this.value.AuxOrganization.Name
      ? this.value.AuxOrganization.Name
      : organizationName;
  }

  get seedYearLocale(): SeedYearLocale {
    return (
      this.value.SeedYear.SeedYearLocales.find(
        x => x.Locale === this.$i18n.locale
      ) || new SeedYearLocale(this.$i18n.locale)
    );
  }

  get shippingCompany(): string {
    return this.value.SeedOrderShippingInfo.CompanyName;
  }

  get addresse(): string {
    return this.value.SeedOrderShippingInfo.Addresse;
  }

  get street(): string {
    return this.value.SeedOrderShippingInfo.Line1;
  }

  get city(): string {
    return this.value.SeedOrderShippingInfo.City;
  }

  get region(): string {
    return this.$t(this.value.SeedOrderShippingInfo.RegionName) as string;
  }

  get postalCode(): string {
    return this.value.SeedOrderShippingInfo.PostalCode;
  }

  get country(): string {
    return this.value.SeedOrderShippingInfo.Country;
  }

  onTermsClick() {
    this.$emit("update:termsAccepted", this.termsOk);
  }
}
