
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import Guid from "@/types/Guid";
import ClassSubmissionComponent from "@/components/classroom-submission/ClassSubmissions.vue";
import ClassSubmission from "@/types/SeedLabelSubmission/ClassSubmission";
import SeedLabelSubmissionItem from "@/types/SeedLabelSubmission/SeedLabelSubmissionItem";

@Component({
  components: { ClassSubmissionComponent }
})
export default class ClassroomSubmission extends Vue {
  @Prop({ default: () => [] }) classSubmissions!: Array<ClassSubmission>;
  @Prop({ default: Guid.Empty }) headerId!: string;
  @Prop({ default: () => [] }) labels!: Array<SeedLabel>;

  addItem() {
    if (!this.labels.length) return;
    this.classSubmissions.push(this.createClassSubmission());
  }

  createClassSubmission() {
    const classSubmission = new ClassSubmission();
    this.labels.forEach((label: SeedLabel) =>
      classSubmission.submissions.push(this.createSubmissionItem(label))
    );
    return classSubmission;
  }

  createSubmissionItem(label: SeedLabel) {
    const item = new SeedLabelSubmissionItem();
    item.SeedLabelSubmissionHeaderId = this.headerId;
    item.SeedLabelId = label.Id;
    item.SeedLabel = label;
    return item;
  }

  @Emit()
  removeItem(value: ClassSubmission) {
    return value;
  }
}
