import { RouteConfig } from "vue-router";
import SubmitResults from "@/views/SubmitResults.vue";
import SubmissionResults from "@/views/SubmissionResults.vue";
import NationalResults from "@/views/NationalResults.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Results/Submit",
    name: "SubmitResults",
    component: SubmitResults,
    meta: {
      authName: "main"
    }
  },
  {
    path: "/Results",
    name: "SubmissionResults",
    component: SubmissionResults,
    meta: {
      authName: "main"
    }
  },
  {
    path: "/NationalResults",
    name: "NationalResults",
    component: NationalResults
  }
];

export default routes;
