
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuxOrganization from "@/types/AuxOrganization";
import ProvinceSelect from "@/components/selects/ProvinceSelect.vue";
import PostalCodeField from "@/components/PostalCodeField.vue";

@Component({
  components: {
    ProvinceSelect,
    PostalCodeField
  }
})
export default class AuxOrganizationInfo extends Vue {
  @Prop(Object) value: AuxOrganization | undefined;

  auxOrganization: AuxOrganization = this.value || new AuxOrganization();

  @Watch("value")
  onValueChanged() {
    this.auxOrganization = this.value || new AuxOrganization();
  }
}
