
import Vue from "vue";
import Results from "@/views/admin/Orders/Search/Results.vue";
import SearchInput from "@/components/search/SearchInput.vue";
import SeedYearSelect from "@/components/selects/SeedYearSelect.vue";
import orderModule from "@/modules/order.ts";
import { getModule } from "vuex-module-decorators";
import { Component } from "vue-property-decorator";
import { debounce } from "lodash";
import seedYearsModule from "@/modules/seedYears";
import SeedYear from "@/types/SeedYear/SeedYear";

@Component({
  components: {
    SeedYearSelect,
    Results,
    SearchInput
  }
})
export default class Orders extends Vue {
  orderState = getModule(orderModule, this.$store);
  seedYearsState = getModule(seedYearsModule, this.$store);
  public searchOrders = debounce((searchTerm: string) => {
    this.search(searchTerm);
  }, 500);

  search(searchTerm: string) {
    this.orderState.UpdateSearchTerm(searchTerm);
  }

  onSeedYearSelectInput(value: Array<SeedYear>) {
    this.orderState.SetSearchSeedYearIds(
      value.flatMap(item => item.Id).join(",")
    );
  }

  searching() {
    this.orderState.UpdateIsSearching(true);
  }

  mounted() {
    this.orderState.ResetSelectedOrder();
  }
}
