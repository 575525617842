import Swal from "sweetalert2";

export interface ToastMessage {
  message?: string;
  type: "success" | "error" | "info" | "warning" | "question" | undefined;
}

export const toastMessage = ({ message, type }: ToastMessage) => {
  Swal.fire({
    toast: true,
    position: "bottom-right",
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 1500
  });
};
