
import { Vue, Component, Prop } from "vue-property-decorator";
import SeedOrderClassGrade from "@/types/SeedOrder/SeedOrderClassGrade";
import { getModule } from "vuex-module-decorators";
import classroomPickerModule from "@/modules/classroomPicker";
import EditGradeModal from "@/components/modals/EditGradeModal.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

@Component({
  components: { DeleteIcon, EditGradeModal }
})
export default class GradeItem extends Vue {
  @Prop({ default: () => new SeedOrderClassGrade() })
  grade!: SeedOrderClassGrade;
  @Prop({ default: false }) editable!: boolean;

  showEditModal = false;
  classroomState = getModule(classroomPickerModule, this.$store);

  get gradeText() {
    const foundGrade = this.classroomState.Grades.find(
      g => g.Id === this.grade.GradeId
    );
    return foundGrade ? foundGrade.Text : "";
  }

  get NumberOfStudents() {
    return `${this.$t("Students")}: ${this.grade.NumStudents}`;
  }

  get selectedGrades() {
    const [classroom] = this.classroomState.Classes.filter(
      item => item.Id === this.grade.SeedOrderClassId
    );
    return classroom.SeedOrderClassGrades;
  }

  deleteGrade() {
    this.classroomState.RemoveGrade(this.grade);
  }

  update(value: SeedOrderClassGrade) {
    this.classroomState.UpdateGrade(value);
  }

  toggle() {
    this.showEditModal = !this.showEditModal;
  }
}
