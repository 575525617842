import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("SchoolInformation")))]),_c(VForm,{ref:"form"},[_c(VSwitch,{staticClass:"pb-0",attrs:{"label":_vm.$t('CantFindSchool')},on:{"click":_vm.auxSchoolClick},model:{value:(_vm.auxSchool),callback:function ($$v) {_vm.auxSchool=$$v},expression:"auxSchool"}}),_c('div',{staticClass:"mb-2"},[(!_vm.auxSchool)?_c('SchoolLookup',{ref:"schoolLookup",attrs:{"label":_vm.$t('SelectSchool'),"rules":[
          () =>
            _vm.order.OrganizationId.length > 0 ||
            _vm.auxSchool ||
            `${_vm.$t('Rule_SchoolName')}`
        ]},on:{"input":_vm.onOrganizationInput},model:{value:(_vm.order.Organization),callback:function ($$v) {_vm.$set(_vm.order, "Organization", $$v)},expression:"order.Organization"}}):_c('AuxOrganizationInfo',{model:{value:(_vm.order.AuxOrganization),callback:function ($$v) {_vm.$set(_vm.order, "AuxOrganization", $$v)},expression:"order.AuxOrganization"}})],1),_c('h4',[_vm._v(_vm._s(_vm.$t("NumAdults")))]),_c(VSelect,{staticStyle:{"max-width":"250px"},attrs:{"label":_vm.$t('NumAdultsLabel'),"items":_vm.educatorItems,"rules":[() => _vm.order.NumEducators > 0 || `${_vm.$t('Rule_NumEducators')}`]},model:{value:(_vm.order.NumEducators),callback:function ($$v) {_vm.$set(_vm.order, "NumEducators", $$v)},expression:"order.NumEducators"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }