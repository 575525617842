import { RouteConfig } from "vue-router";
import EditOrder from "@/views/admin/Orders/EditOrder.vue";
import OrderSeeds from "@/views/OrderSeeds.vue";
import Orders from "@/views/admin/Orders/Orders.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Admin/Orders/Edit",
    name: "EditOrder",
    component: EditOrder
  },
  {
    path: "/Order",
    name: "OrderSeeds",
    alias: "/",
    component: OrderSeeds,
    meta: {
      authName: "main"
    }
  },
  {
    path: "/Admin/Orders",
    name: "Orders",
    component: Orders,
    meta: {
      authName: "main"
    }
  }
];

export default routes;
