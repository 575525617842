
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import SeedLabelSubmissionItem from "../../types/SeedLabelSubmission/SeedLabelSubmissionItem";

@Component
export default class SubmissionItem extends Vue {
  @Prop({ default: () => new SeedLabelSubmissionItem() })
  item!: SeedLabelSubmissionItem;

  get plantInputRules() {
    return [(value: number) => value > 0];
  }

  get plantErrorMessages() {
    return [
      (this.item.Planted || "") == "" ? this.$t("Rule_GreaterThan0") : "",
      (!this.item.Planted || this.item.Planted) < 1
        ? this.$t("Rule_GreaterThan1")
        : ""
    ].filter(item => item.length);
  }

  get germinatedInputRules() {
    return [
      (value: number) => value >= 0,
      (value: number) => value <= (this.item.Planted || 0)
    ];
  }

  get germinatedErrorMessages() {
    console.log(Number(this.item.Germinated));
    return [
      (this.item.Germinated || "") == "" ? this.$t("Rule_GreaterThan0") : "",
      (this.item.Germinated || 0) < -1 ? this.$t("Rule_GreaterThan0") : "",
      (!this.item.Germinated || this.item.Germinated) >
      (!this.item.Planted || this.item.Planted)
        ? this.$t("Rule_GreaterThanPlanted")
        : ""
    ].filter(item => item.length);
  }

  @Emit()
  removeItem() {
    return this.item.Id;
  }
}
