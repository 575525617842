
import Vue from "vue";
import { Component } from "vue-property-decorator";
import orderModule from "@/modules/order";
import { getModule } from "vuex-module-decorators";
import ResultLineItem from "@/views/admin/Orders/Search/ResultLineItem.vue";
import Paging from "@/components/search/Paging.vue";
import SortableHeader from "@/components/table/SortableHeader.vue";

@Component({
  components: {
    ResultLineItem,
    Paging,
    SortableHeader
  }
})
export default class Results extends Vue {
  orderState = getModule(orderModule, this.$store);

  created() {
    this.orderState.SearchOrders();
  }

  onSort(data: { sortProperty: string; isAscendingOrder: boolean }) {
    this.orderState.SetSearchSorting(data);
  }
}
