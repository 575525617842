
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import YearLabel from "@/types/YearLabel";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

@Component({
  components: { DeleteIcon }
})
export default class YearLabelListItem extends Vue {
  @Prop({ default: () => new YearLabel() }) yearLabel!: YearLabel;
  @Prop({ default: 25 }) textLimit!: number;

  get IsTreated() {
    return this.yearLabel.SeedLabel!.Treatment;
  }

  get Name() {
    return this.yearLabel.SeedLabel!.SeedPackLabel;
  }

  truncate(value: string, cutOffPoint: number) {
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  @Emit()
  onDelete() {
    return this.yearLabel;
  }
}
