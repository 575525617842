
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import ResultLineItem from "./ResultLineItem.vue";
import Paging from "@/components/search/Paging.vue";

@Component({
  components: {
    ResultLineItem,
    Paging
  }
})
export default class Results extends Vue {
  @Prop({ default: [] }) labels!: Array<SeedLabel>;
  @Prop({ default: false }) isSearching!: boolean;
  @Prop({ default: 1 }) currentPage!: bigint;
  @Prop({ default: 1 }) lastPage!: bigint;

  @Emit()
  previousPage() {
    return;
  }

  @Emit()
  nextPage() {
    return;
  }

  @Emit()
  onDelete() {
    return;
  }
}
