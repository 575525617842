
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Chart,
  ChartConfiguration,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Legend,
  Title,
  Tooltip
} from "chart.js";
import SeedLabelStatistics from "@/types/SeedYear/SeedLabelStatistics";
import GerminationPercentage from "@/components/analytics/GerminationPercentage.vue";

@Component({
  components: { GerminationPercentage }
})
export default class LabelChartStatistics extends Vue {
  @Prop({ default: () => [] }) stats!: Array<SeedLabelStatistics>;

  canvas!: HTMLCanvasElement;
  chart!: Chart;

  get title() {
    return `Seed Label Statistics for ${this.stats
      .flatMap(item => item.SeedLabel.SeedPackLabel)
      .join(" and ")}`;
  }

  get id() {
    return this.stats.map(stat => stat.SeedLabel.Id).join("--");
  }

  mounted() {
    this.registerChartDependencies();
    this.createChart({
      datasets: this.dataSet(),
      labels: this.labels()
    });
  }

  registerChartDependencies() {
    Chart.register(
      BarElement,
      BarController,
      LinearScale,
      CategoryScale,
      Legend,
      Title,
      Tooltip
    );
  }

  dataSet() {
    return [
      {
        data: this.stats.flatMap(item => [item.Planted, item.Germinated]),
        backgroundColor: [
          "rgb(138,168,255,0.2)",
          "rgb(62,140,53,0.2)",
          "rgb(138,168,255,0.2)",
          "rgb(62,140,53,0.2)"
        ],
        borderColor: [
          "rgb(138,168,255)",
          "rgb(62,140,53)",
          "rgb(138,168,255)",
          "rgb(62,140,53)"
        ],
        borderWidth: 1
      }
    ];
  }

  labels() {
    return this.stats.flatMap(item => [
      `Planted (${item.SeedLabel.SeedPackLabel})`,
      `Germinated (${item.SeedLabel.SeedPackLabel})`
    ]);
  }

  createChart(chartData: object) {
    this.canvas = document.getElementById(this.id) as HTMLCanvasElement;

    const options = {
      type: "bar",
      data: chartData,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    } as ChartConfiguration;

    this.chart = new Chart(this.canvas, options);
  }
}
