import Pagination from "@/types/Interfaces/Pagination";
import SeedLabel from "@/types/SeedLabels/SeedLabel";

export default class SearchSeedLabelPagination
  implements Pagination<SeedLabel> {
  SearchTerm: string;
  AscendingOrder: boolean;
  CurrentPage: number;
  LastPage: number;
  PageSize: number;
  SortProp: string;
  Total: number;
  Value: Array<SeedLabel>;

  constructor() {
    this.AscendingOrder = true;
    this.CurrentPage = 1;
    this.LastPage = 0;
    this.PageSize = 20;
    this.SortProp = "";
    this.Total = 0;
    this.Value = [];
    this.SearchTerm = "";
  }
}
