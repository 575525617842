import axios from "axios";
import SecurityService from "@/services/Auth/SecurityService";
import { GetConfigForEnvironment } from "../env-config";

export const securityService = new SecurityService();
const config = GetConfigForEnvironment();

export const axiosApi = axios.create({
  baseURL: config.apiUrl
});

axiosApi.interceptors.request.use(async req => {
  try {
    const user = await securityService.userManager.getUser();
    if (!user) {
      req.headers["Authorization"] = `Bearer`;
    } else if (req.headers["Authorization"] === undefined) {
      req.headers["Authorization"] = `Bearer ${user.access_token}`;
    }
  } catch (error) {
    return Promise.reject(error);
  }
  return req;
});

export const axiosCanadaPostApi = axios.create({
  baseURL: "https://ws1.postescanada-canadapost.ca/"
});

export const axiosId = axios.create({
  baseURL: config.idUrl
});

export abstract class BaseApiService {
  async defineHeader() {
    try {
      const token = await securityService.GetAccessToken();
      axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error(error);
    }
  }

  /* eslint-disable */
  async All(promises: any[]) {
    await this.defineHeader();    
    return axios.all(promises);
  }
  /* eslint-enable */
}
