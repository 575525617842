
import { Component, Prop, Vue } from "vue-property-decorator";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import SubmissionModule from "@/modules/submission";
import { getModule } from "vuex-module-decorators";

@Component
export default class ResultLineItem extends Vue {
  @Prop() submissionGroup!: SubmissionGroup;
  @Prop({ default: 25 }) textLimit!: number;

  submissionState = getModule(SubmissionModule, this.$store);

  get Name() {
    return this.submissionGroup.Name;
  }

  truncate(value: string, cutOffPoint: number) {
    return value.length > cutOffPoint
      ? value.substring(0, cutOffPoint).concat("...")
      : value;
  }

  edit() {
    this.submissionState.setSelectedSubmissionGroup(this.submissionGroup);
    this.$router.push({ name: "EditSubmissionGroup" });
  }
}
