
import { Component, Vue } from "vue-property-decorator";
import SeedYearRadioGroup from "@/components/SubmissionGroupRadioGroup.vue";
import LabelChartStatistics from "@/components/analytics/LabelChartStatistics.vue";
import LabelStatisticWidget from "@/components/analytics/LabelStatisticWidget.vue";

@Component({
  components: {
    LabelStatisticWidget,
    LabelChartStatistics,
    SeedYearRadioGroup
  }
})
export default class Dashboard extends Vue {}
