import { RouteConfig } from "vue-router";
import SeedYearList from "@/views/admin/SeedYear/SeedYearList.vue";
import SeedYearCreate from "@/views/admin/SeedYear/SeedYearCreate.vue";
import EditYear from "@/views/admin/SeedYear/EditYear.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/Admin/SeedYear",
    name: "SeedYearList",
    component: SeedYearList,
    meta: {
      authName: "main"
    }
  },
  {
    path: "/Admin/SeedYear/Create",
    name: "SeedYearCreate",
    component: SeedYearCreate,
    meta: {
      authName: "main"
    }
  },
  {
    path: "/Admin/SeedYear/Update",
    name: "EditYear",
    component: EditYear,
    meta: {
      authName: "main"
    }
  }
];

export default routes;
