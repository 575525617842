
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SchoolLookup from "@/components/SchoolLookup.vue";
import SeedLabelSubmissionHeader from "@/types/SeedLabelSubmission/SeedLabelSubmissionHeader";
import AuxOrganizationInfo from "@/components/AuxOrganizationInfo.vue";
import SubmissionApiService from "@/services/Submission/SubmissionApiService";
import IntField from "@/components/IntField.vue";
import ClassroomSubmission from "@/components/classroom-submission/ClassroomSubmission.vue";
import ClassSubmission from "@/types/SeedLabelSubmission/ClassSubmission";
import OrganizationLookup from "@/components/OrganizationLookup.vue";
import AuxOrganization from "@/types/AuxOrganization";
import Organization from "@/types/Organization";
import Guid from "@/types/Guid";
import { toastMessage } from "@/types/Interfaces/ToastMessage";
import SubmissionGroupLookup from "@/components/SubmissionGroupLookup.vue";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("SubmitResults") as string
    };
  },
  components: {
    SubmissionGroupLookup,
    OrganizationLookup,
    ClassroomSubmission,
    SchoolLookup,
    AuxOrganizationInfo,
    IntField
  }
})
export default class SubmitResults extends Vue {
  submissionApiService: SubmissionApiService = new SubmissionApiService();
  submissionHeader: SeedLabelSubmissionHeader = new SeedLabelSubmissionHeader();
  submissionGroup: SubmissionGroup = new SubmissionGroup();
  classSubmissions: Array<ClassSubmission> = [];
  isFormValid = true;
  useAuxOrganization = false;

  get selectedLabels() {
    return this.submissionGroup
      ? this.submissionGroup.SubmissionGroupLabels.flatMap(item => item.Label)
      : [];
  }

  get organizationSelected() {
    return (
      this.submissionHeader.OrganizationId.length > 0 &&
      this.submissionHeader.OrganizationId !== Guid.Empty
    );
  }

  get organizationLookupRules() {
    return [() => this.organizationSelected];
  }

  get organizationLookupErrorMessages() {
    return [!this.organizationSelected ? this.$t("Rule_School") : ""].filter(
      item => item.length
    );
  }

  get submissionGroupLookupRules() {
    return [this.selectedLabels.length > 0];
  }

  get yearSelectErrorMessages() {
    return [!this.selectedLabels.length ? this.$t("Rule_Season") : ""].filter(
      item => item.length
    );
  }

  get submissionRules() {
    return [this.classSubmissions.length > 0];
  }

  get submissionErrorMessages() {
    return [
      !this.selectedLabels.length ? this.$t("Rule_SubmissionGroup") : "",
      !(this.classSubmissions.length > 0) ? this.$t("Rule_Classes") : ""
    ]
      .filter(item => item.length)
      .join(", ");
  }

  resetClasses() {
    this.classSubmissions = [];
  }

  onSubmissionGroupInput() {
    this.classSubmissions = [];
  }

  toggleAuxOrganization() {
    this.useAuxOrganization = !this.useAuxOrganization;
    if (!this.useAuxOrganization) {
      this.submissionHeader.AuxOrganization = undefined;
    } else {
      this.submissionHeader.AuxOrganization = new AuxOrganization();
      this.submissionHeader.OrganizationId = "";
    }
  }

  submitResults() {
    console.log("submit results");
    (this.$refs.SubmitResultForm as HTMLFormElement).validate();
    if (!this.isFormValid) return;

    this.submissionHeader.Locale = this.$i18n.locale;
    this.submissionHeader.SeedLabelSubmissionItems = this.classSubmissions.flatMap(
      item => item.submissions
    );
    this.submissionApiService
      .CreateSubmissionHeader(this.submissionHeader)
      .then(r => {
        toastMessage({
          message: this.$t("SubmittedResults") as string,
          type: "success"
        });
        this.$router.push(
          `/Results?id=${this.submissionGroup.Id}&hid=${r.Value}`
        );
      })
      .catch(error => {
        console.error(error);
        toastMessage({
          message: this.$t("FailedSubmitResults") as string,
          type: "error"
        });
      });
  }

  removeClassroom(value: ClassSubmission) {
    const removalIndex = this.classSubmissions.indexOf(value);
    this.classSubmissions.splice(removalIndex, 1);
  }

  onOrganizationInput(value: Organization) {
    this.submissionHeader.OrganizationId = value.Id;
  }
}
