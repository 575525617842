import BaseEntity from "@/types/BaseEntity";
import SeedYear from "@/types/SeedYear/SeedYear";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";
import Guid from "@/types/Guid";

export default class SeedYearSubmissionGroup extends BaseEntity {
  SeedYearId: string = Guid.Empty;
  SubmissionGroupId: string = Guid.Empty;
  SeedYear: SeedYear | undefined;
  SubmissionGroup: SubmissionGroup | undefined;
}
