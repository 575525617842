
import { Component, ModelSync, Vue } from "vue-property-decorator";
import SeedYear from "../types/SeedYear/SeedYear";
import DatePicker from "@/components/DatePicker.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import LabelLookup from "@/components/LabelLookup.vue";
import SeedLabel from "@/types/SeedLabels/SeedLabel";
import SeedYearLocale from "@/types/SeedYear/SeedYearLocale";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("AddSeason") as string
    };
  },
  components: {
    DatePicker,
    ConfirmButton,
    LabelLookup
  }
})
export default class SeedYearInfo extends Vue {
  @ModelSync("year", "input", { default: () => new SeedYear() })
  seedYear!: SeedYear;
  seedLabel: SeedLabel = new SeedLabel();

  headers: object[] = [
    {
      text: this.$root.$t("Label"),
      align: "left",
      value: "SeedPackLabel"
    },
    {
      text: this.$root.$t("Treatment"),
      align: "left",
      value: "Treatment"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  seedYearLocale(locale: string): SeedYearLocale {
    return (
      this.seedYear.SeedYearLocales.find(x => x.Locale === locale) ||
      new SeedYearLocale(locale)
    );
  }
}
