
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ThankYou extends Vue {
  // get French(): boolean {
  //   return this.$i18n.locale === "fr";
  // }

  onCloseClick() {
    this.$emit("close");
  }
}
