
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EnumerationApiService from "@/services/EnumerationApiService";
import SeedOrder from "@/types/SeedOrder/SeedOrder";
import ClassroomItem from "@/components/classroom-info/ClassroomItem.vue";
import SeedOrderClass from "@/types/SeedOrder/SeedOrderClass";
import TSEnumerationLocale from "@/types/EnumerationLocale";

@Component({
  components: {
    ClassroomItem
  }
})
export default class ClassroomInfo extends Vue {
  @Prop({ default: () => new SeedOrder() }) value!: SeedOrder;
  @Prop({ default: true }) showNoResults!: boolean;

  order: SeedOrder = this.value;
  grades: Array<TSEnumerationLocale> = [];
  enumApiService: EnumerationApiService = new EnumerationApiService();

  mounted() {
    this.retrieveGrades();
  }

  retrieveGrades() {
    this.enumApiService
      .GetGrades()
      .then(response => {
        this.grades = response.Value;
      })
      .catch(error => {
        console.error(error);
      });
  }

  addClass() {
    const orderClass = new SeedOrderClass();
    orderClass.SeedOrderId = this.order.Id;
    if (this.order.SeedOrderClasses.length < 10)
      this.order.SeedOrderClasses.push(orderClass);
  }

  removeClass(value: SeedOrderClass) {
    const orderClassIndex = this.order.SeedOrderClasses.indexOf(value);
    this.order.SeedOrderClasses.splice(orderClassIndex, 1);
  }
}
