import Vue from "vue";
import TomatosphereApp from "./views/TomatosphereApp.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { i18n } from "./i18n";
import store from "./store";
import VueGtm from "vue-gtm";
import VueGtag from "vue-gtag"
import VueMeta from "vue-meta";
import "./assets/css/main.scss";
import VueMask from "v-mask";

Vue.use(VueMask);
Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: ["GTM-WHMF9DX", "GTM-WFJL2R4"], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueGtag, {
  config: { id: "G-CKD6NNRZ9W" }
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(TomatosphereApp)
}).$mount("#app");
