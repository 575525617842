
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import OrganizationApiService from "@/services/OrganizationApiService";
import Organization from "@/types/Organization.ts";

@Component
export default class SchoolLookup extends Vue {
  @Prop(Object) value: Organization | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(String) label: string | undefined;

  @Watch("value")
  onValueChanged() {
    this.org = this.value || new Organization();
  }

  org: Organization = this.value || new Organization();
  search = "";
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  // eslint-disable-next-line
  timeout: any | null;
  loading = false;
  orgs: object[] = [];

  orgApiService: OrganizationApiService = new OrganizationApiService();

  onOrganizationKeyUp() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.doSearch, this.typeTimer);
  }

  doSearch() {
    this.loading = true;
    this.orgApiService
      .GetOrganizations(this.search)
      .then(resp => {
        this.orgs = [];
        resp.Value.forEach((x: Organization) => {
          this.orgs.push({
            text: `${x.Name} - ${x.Address} (${x.City})`,
            value: x
          });
        });
      })
      .finally(() => (this.loading = false));
  }

  onInput() {
    this.$emit("input", this.org);
  }

  Validate() {
    // eslint-disable-next-line
    return (this.$refs.schoolLookup as any).validate();
  }
}
