import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocales from "./locales/en.json";
import frLocales from "./locales/fr.json";

Vue.use(VueI18n);

export const langCode = window.location.origin.includes("parlonssciences")
  ? "fr"
  : "en";

export const i18n = new VueI18n({
  locale: langCode || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: {
      ...enLocales
    },
    fr: {
      ...frLocales
    }
  }
});
