
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import SubmissionGroup from "@/types/SeedLabelSubmission/SubmissionGroup";

@Component
export default class SeedYearRadioGroup extends Vue {
  @ModelSync("value", "onChange", { default: () => new SubmissionGroup() })
  submissionGroup!: SubmissionGroup;
  @Prop({ default: () => [] }) submissionGroups!: Array<SubmissionGroup>;
  @Prop({ default: false }) row!: boolean;
}
