
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SeedYearApiService from "@/services/SeedYear/SeedYearApiService";
import SeedYear from "@/types/SeedYear/SeedYear";
import ConfirmButton from "@/components/ConfirmButton.vue";
import SeedYearListItem from "@/views/admin/SeedYear/SeedYearListItem.vue";

@Component({
  metaInfo() {
    return {
      title: this.$root.$t("SeedYears") as string
    };
  },
  components: {
    SeedYearListItem,
    ConfirmButton
  }
})
export default class SeedYearList extends Vue {
  apiService: SeedYearApiService = new SeedYearApiService();
  seedYears: SeedYear[] = [];
  headers: object[] = [
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("PlantingPeriod"),
      align: "left",
      value: "PlantingPeriod"
    },
    {
      text: this.$root.$t("ShippingWindow"),
      align: "left",
      value: "ShippingWindow"
    },
    {
      text: this.$root.$t("StartDate"),
      align: "left",
      value: "StartDate"
    },
    {
      text: this.$root.$t("EndDate"),
      align: "left",
      value: "EndDate"
    },
    {
      text: this.$root.$t("Active"),
      align: "left",
      value: "IsActive"
    }
  ];

  mounted() {
    this.getData();
  }

  getData() {
    this.apiService.GetSeedYears().then(resp => {
      this.seedYears = resp.Value;
    });
  }

  onAddClick() {
    this.$router.push(`/Admin/SeedYear/Create`);
  }
}
