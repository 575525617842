import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.toggle},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"add-class-btn",attrs:{"elevation":0,"color":"green darken-1","icon":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"d-sr-only-focusable"},[_vm._v(_vm._s(_vm.$t("Add")))]),_c(VIcon,{attrs:{"medium":""}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c(VCard,[_c(VForm,{ref:"AddSubmissionGroupForm",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t("AddGroup")))]),_c(VCardText,{staticClass:"mt-4"},[_c('submission-group-lookup',{staticStyle:{"display":"inline"},attrs:{"label":_vm.$t('Group'),"rules":_vm.lookupRules,"selected-value":_vm.group,"error-messages":_vm.errorMessages,"hide-no-data":""},on:{"input":_vm.addGroup}})],1),_c(VCardActions,{staticClass:"justify-space-between"},[_c(VBtn,{on:{"click":_vm.toggle}},[_vm._v("Close")]),_c(VBtn,{staticClass:"primary",attrs:{"text":"","type":"submit"}},[_vm._v("Save")])],1)],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }