import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import SeedYearApiService from "@/services/SeedYear/SeedYearApiService";
import SeedYear from "@/types/SeedYear/SeedYear";

@Module({ namespaced: true, name: "seedYears" })
export default class SeedYears extends VuexModule {
  seedYears: Array<SeedYear> = [];
  selectedSeedYear: SeedYear | undefined;
  seedYearApiService: SeedYearApiService = new SeedYearApiService();

  /**
   * @Getters
   */
  get SelectedSeedYear() {
    return this.selectedSeedYear;
  }

  get SeedYears() {
    return this.seedYears;
  }

  get ActiveSeedYears() {
    return this.seedYears.filter(sy => sy.IsActive);
  }

  /**
   * @Mutations
   */
  @Mutation
  SET_SEED_YEARS(values: Array<SeedYear>) {
    this.seedYears = values;
  }

  /**
   * @Actions
   */
  @Action
  FetchSeedYears() {
    this.seedYearApiService
      .GetSeedYears()
      .then(response => {
        this.context.commit("SET_SEED_YEARS", response.Value);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
