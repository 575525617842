import Guid from "./Guid";

export default class Organization {
  Id: string = Guid.Empty;
  Name = "";
  CountryId: string = Guid.Empty;
  RegionId: string = Guid.Empty;
  City = "";
  Address = "";
  PostalCode = "";
  TelephoneNumber = "";
  FaxNumber = "";
  IsLowIncome = false;
  IsPrimarilyFnmi = false;
  RepresentedFnmi = "";
  GenderDistributionId: string = Guid.Empty;
  LanguageId: string = Guid.Empty;
  TypeId: string = Guid.Empty;
  SubTypeId = "";
  SchoolBoardId = "";
  Validated = false;
  SchoolBoard = "";
  Editable = false;
}
